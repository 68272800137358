import React, { CSSProperties } from 'react';
import styles from './page-title.module.scss';
import cn from 'classnames';

type Props = React.HTMLProps<HTMLDivElement> & {
  title: React.ReactNode;
  right?: React.ReactNode;
  padding?: string;
  margin?: string;
  style?: CSSProperties;
};

export const PageTitle = (props: React.PropsWithChildren<Props>) => {
  const {
    title,
    style,
    right,
    className,
    ...titleProps /*padding = '0.5rem 0 0.25rem 0', margin = '1.4rem 0 0 0', right*/
  } = props;

  return (
    <div className={styles.card}>
      <div className={styles.cardHeader}>
        <h4 className={cn(styles.cardTitle, className)} style={style} {...titleProps}>
          {title}
        </h4>
        {props.children}
      </div>
      {right}
    </div>
  );
};
