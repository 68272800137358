import ReactDOM from 'react-dom';
import './index.css';
import { StoreContext } from './stores/use-store.tsx';
import { RootStore } from './stores/root-store.tsx';
import App from './App';
import React from 'react';

// const locales1:Array<string>= ['en', 'ru', 'es', 'fr', 'de', 'he']

declare global {
  var translation: any;
}
const Render = () => {
  return (
    <StoreContext.Provider value={new RootStore()}>
      <App />
    </StoreContext.Provider>
  );
};
ReactDOM.render(<Render />, document.getElementById('root'));
