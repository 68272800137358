import axios, { AxiosInstance } from 'axios';

const apiURL = 'https://console.phonedo.cloud';
//const apiURL = "https://console.phone.do";
//const apiURL = process.env.REACT_API_URL;

export class ApiClient {
  constructor(private _client: AxiosInstance) {
    this._client = axios;
  }

  test = (id: string) => {
    return this._client
      .get<{ token: string }>(`/api/tenants/${id}/_impersonate`)
      .then((response) => response.data);
  };

  login() {
    return this._client
      .post(
        `${apiURL}/api/partner_users/login_check`,
        {
          username: '972552996071',
          password: '1234',
        },
        {
          headers: {
            accept: 'application/ld+json',
            'content-type': 'application/ld+json',
          },
        }
      )
      .then((response) => response.data);
  }

  async sendEmail(body: object) {
    await this.login().then((res) => {
      return this._client
        .post(`${apiURL}/api/personal_email_supports`, body, {
          headers: {
            accept: 'application/ld+json',
            'content-type': 'application/ld+json',
            authorization: `bearer ${res.token}`,
          },
        })
        .then((response) => response.data);
    });
  }
}
