import { FieldRenderProps } from 'react-final-form';
import DatePicker from 'react-date-picker';
import React from 'react';
import cn from 'classnames';
import styles from './form.module.scss';

type Props<T> = FieldRenderProps<T, HTMLInputElement>;

export const InputField = <T extends string | number>(props: Props<T>) => {
  const { meta, input } = props;

  const date = input.value && props.inputType === 'date' ? new Date(input.value) : new Date();
  return (
    <>
      <div
        className={
          meta.submitError
            ? cn(props.inputClass ? props.inputClass : styles.inputText, styles.feildError)
            : props.inputClass
            ? props.inputClass
            : styles.inputText
        }
      >
        {input.value && <div className={styles.smallPlaceholder}>{props.placeholder}</div>}
        <div className={styles.flex}>
          <div className={styles.inputItem}>
            {props.inputType === 'date' ? (
              <DatePicker
                className={
                  input.value !== ''
                    ? styles.inputfiled
                    : `${styles.inputfiled} ${styles.inputfiledBig}`
                }
                onChange={input.onChange}
                value={date}
              />
            ) : (
              <input
                id={props.id}
                className={
                  props.inputSmallClass
                    ? input.value !== ''
                      ? props.inputSmallClass
                      : cn(props.inputSmallClass, styles.inputValue)
                    : input.value !== ''
                    ? styles.inputfiled
                    : cn(styles.inputfiled, styles.inputValue)
                }
                accept={props.inputAccept && props.inputAccept}
                type={props.inputType}
                value={input.value}
                onChange={input.onChange}
                disabled={props.disabled}
                placeholder={props.placeholder}
                style={props.style}
                autoFocus={props.autoFocus}
                autoComplete={props.autoComplete}
              />
            )}
          </div>
          {props.icon && (
            <div
              id={input.value !== '' ? `${styles.iconPosition}` : ''}
              className={`${styles.iconStyle} ${styles.inputItemRight}`}
            >
              {props.icon}
            </div>
          )}
        </div>
      </div>

      {(meta.touched && meta.error) ||
        (meta.submitError && (
          <div className={styles.submitError}>{meta.error || meta.submitError}</div>
        ))}

      {/* <div className={styles.submitError}>{<I18n t={(meta.error) || (meta.submitError)}/>}</div> */}
    </>
  );
};
