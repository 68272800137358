import { ApiClient } from '../api/api-client.tsx';
import { LandingPageStore } from './landing-page-store.tsx';

import Axios from 'axios';

export class RootStore {
  apiclient = new ApiClient(Axios);

  landingPageStore = new LandingPageStore(this.apiclient);
}
