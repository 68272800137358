import { handleFormSubmit } from '../screens/ui/final-form.ts';
import { ApiClient } from '../api/api-client.tsx';
import { v4 as uuidv4 } from 'uuid';
import { runInAction } from 'mobx';
export type ConectUsForm = {
  fullName: string;
  companyName: string;
  emailAddress: string;
  description: string;
  countryCode: string;
  telephone: string;
  type: string;
  formButton: 'SendTheMassage' | null;
};

export class LandingPageStore {
  conectUsForm?: ConectUsForm;
  constructor(public apiClient: ApiClient) {
    // makeAutoObservable(this, {
    //     apiClient: false,
    // })
  }

  submitConactUs = async (form: any) => {
    const toEmail = `${form?.type.value}@full.do`;

    const messageData = {
      id: uuidv4(),
      subject: 'Full.do new lead',
      toEmail: toEmail,
      body: `Full Name: ${form?.fullName} Company Name: ${form?.companyName} Email Address: ${form?.emailAddress} elephone: ${form?.countryCode.value}${form?.telephone} Description: ${form?.description}`,
    };
    const sendEmailRes = await handleFormSubmit(this.apiClient.sendEmail(messageData));
    if (sendEmailRes.errors) {
      return sendEmailRes.errors;
    }
    alert('The email was sent successfully');
    this.conectUsForm = form;
    runInAction(() => (this.conectUsForm = form));
  };
}
