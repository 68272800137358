import React from 'react';
import { observer } from 'mobx-react';
import Menu from '@material-ui/core/Menu';
import Button from '@material-ui/core/Button';
import caretDownIcon from '../../images/arrow_down_icon.svg';
import { useHistory } from 'react-router-dom';
import styles from '../../screens/landingPage/landing-page.module.scss';
export const savedLanguage = 'selectedLanguage';

export type language = {
  name: string;
  code: string;
  img: string;
};
export const LanguageMenu = observer((props: any) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const setLanguageDirection = props.setLanguageDirection;
  // localStorage.setItem(savedLanguage, selectedLanguage);
  const languages: language[] = [
    {
      name: 'עברית',
      code: 'he',
      img: 'https://purecatamphetamine.github.io/country-flag-icons/3x2/IL.svg',
    },
    {
      name: 'English',
      code: 'en',
      img: 'https://purecatamphetamine.github.io/country-flag-icons/3x2/US.svg',
    },
    {
      name: 'اللغة العربية',
      code: 'ar',
      img: 'https://purecatamphetamine.github.io/country-flag-icons/3x2/AE.svg',
    },
    {
      name: 'Magyar',
      code: 'hu',
      img: 'https://purecatamphetamine.github.io/country-flag-icons/3x2/HU.svg',
    },
    {
      name: 'Ελληνικά',
      code: 'gr',
      img: 'https://purecatamphetamine.github.io/country-flag-icons/3x2/GR.svg',
    },
    {
      name: 'Русский',
      code: 'ru',
      img: 'https://purecatamphetamine.github.io/country-flag-icons/3x2/RU.svg',
    },
    {
      name: 'Español',
      code: 'es',
      img: 'https://purecatamphetamine.github.io/country-flag-icons/3x2/ES.svg',
    },
    {
      name: 'Français',
      code: 'fr',
      img: 'https://purecatamphetamine.github.io/country-flag-icons/3x2/FR.svg',
    },
    {
      name: 'Deutsch',
      code: 'de',
      img: 'https://purecatamphetamine.github.io/country-flag-icons/3x2/DE.svg',
    },
    {
      name: 'Nederlands',
      code: 'nl',
      img: 'https://purecatamphetamine.github.io/country-flag-icons/3x2/NL.svg',
    },
  ];
  const titleObj: { [key: string]: string } = {
    he: 'Full.do | אתר רשמי | החזרת המגע האנושי למסחר אלקטרוני',
    en: 'Full.do | official site | Bringing Back the Human Touch to eCommerce',
    ar: 'Full.do | الموقع الرسمي | إعادة اللمسة الإنسانية إلى التجارة الإلكترونية',
    hu: 'Full.do | hivatalos oldal | Az emberi érintés visszaadása az e-kereskedelemben',
    gr: 'Full.do | επίσημος ιστότοπος | Επαναφέροντας το ανθρώπινο άγγιγμα στο ηλεκτρονικό εμπόριο',
    ru: 'Full.do | официальный сайт | Возвращение человеческого прикосновения к электронной коммерции',
    es: 'Full.do | sitio oficial | Devolviendo el toque humano al comercio electrónico',
    fr: 'Full.do | site officiel | Ramener la touche humaine au commerce électronique',
    de: 'Full.do | offizielle Seite | Die menschliche Note in den E-Commerce zurückbringen',
    nl: 'Full.do | officiële site | De menselijke touch terugbrengen in e-commerce',
    pt: 'Full.do | site oficial | Trazendo de volta o toque humano ao comércio eletrônico',
  };
  const languagesCode = {
    he: 'עברית',
    en: 'English',
    ar: 'اللغة العربية',
    hu: 'Magyar',
    gr: 'Ελληνικά',
    ru: 'Русский',
    es: 'Español',
    fr: 'Français',
    de: 'Deutsch',
    nl: 'Nederlands',
  };
  // var titleObj: Object = {
  //     'he': 'Phone.do עבודה מהבית | האתר הרשמי',
  //     'en': 'Phone.do Official Site | Work From Home',
  //     'ar': '| الموقع الرسمي Phone.do العمل من المنزل',
  //     'hu': 'Phone.do hivatalos | Otthoni munkavégzés',
  //     'gr': 'Phone.do επίσημη ιστοσελίδα | Εργασία από το σπίτι',
  //     'ru': 'официальный сайт Phone.do | Работа из дома',
  //     'es': 'Phone.do Sitio Oficial | Trabajar desde casa',
  //     'fr': 'Phone.do Site officiel | Travail à domicile',
  //     'de': 'Offizielle Phonedo-Website | Von zuhause aus arbeiten',
  //     'nl': 'Officiële Phonedo-site | Thuiswerken'

  // }

  const history = useHistory();

  const handleChange = (event: any, name: any) => {
    setAnchorEl(null);
    const path = window.location.pathname.slice(3);
    history.push(`/${name}${path}`);
    if (name === 'he' || name === 'ar') {
      setLanguageDirection('rtl');
    } else {
      setLanguageDirection('lts');
    }
    document.title = Object.values(titleObj)[Object.keys(titleObj).indexOf(name)];
    props.close && props.close();
  };
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={`${styles.langMenu} ${props.right ? styles.langMenuRtl : ''}`}>
      <Button
        style={{ color: 'white', width: '100%' }}
        className={styles.langBtn}
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <span className={styles.langMenuTitle}>
          {languagesCode[window.location.pathname.slice(1, 3)]}
        </span>
        <img className={styles.langMenuIcon} src={caretDownIcon} alt="caretDownIcon"></img>
      </Button>
      <Menu
        style={
          props.right
            ? { direction: 'rtl', zIndex: 1000, top: '70px' }
            : { direction: 'ltr', zIndex: 1000, top: '70px' }
        }
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {languages.map((item) => (
          <div
            key={item.code}
            className={styles.flagRow}
            style={
              window.location.pathname.slice(1, 3) === item.code
                ? { backgroundColor: '#191919' }
                : { backgroundColor: '#33354E' }
            }
            onClick={(e: any) => {
              handleChange(e, item.code);
            }}
          >
            <img alt="flag" src={item.img} className={styles.flagImg} />
            <span className={styles.flagName}>{item.name}</span>
          </div>
        ))}
      </Menu>
    </div>
  );
});
