import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import 'swiper/components/effect-coverflow/effect-coverflow.min.css';
import 'swiper/components/pagination/pagination.min.css';
import 'swiper/components/navigation/navigation.min.css';
import './carousel.css';
import SwiperCore, { EffectCoverflow, Pagination, Navigation } from 'swiper/core';
import { observer } from 'mobx-react';

const slideImages: { id: number; caption: string; img: string }[] = [
  { id: 1, caption: 'Slide1', img: 'slideOne' },
  { id: 2, caption: 'Slide2', img: 'slideTwo' },
  { id: 3, caption: 'Slide3', img: 'slideThree' },
  { id: 4, caption: 'Slide4', img: 'slideFour' },
  { id: 5, caption: 'Slide5', img: 'slideFive' },
  { id: 6, caption: 'Slide6', img: 'slideSix' },
  { id: 7, caption: 'Slide7', img: 'slideSeven' },
  { id: 8, caption: 'Slide8', img: 'slideEight' },
  { id: 9, caption: 'Slide9', img: 'slideNine' },
  { id: 10, caption: 'Slide10', img: 'slideTen' },
];
SwiperCore.use([EffectCoverflow, Pagination, Navigation]);

export const SliderSection = observer((props: any) => {
  return (
    <Swiper
      navigation={true}
      effect={'coverflow'}
      centeredSlides={true}
      slidesPerView={window.innerWidth < 768 ? 1 : 'auto'}
      loop={true}
      coverflowEffect={{
        rotate: 50,
        stretch: 0,
        depth: 100,
        modifier: 1,
        slideShadows: false,
      }}
      // pagination={{
      //   clickable: true
      // }}
      className="mySwiper"
    >
      {slideImages.map((slideImage, index) => (
        <div key={slideImage.id}>
          <div>
            <SwiperSlide>
              {' '}
              <img
                className={props.swiperSlide}
                alt={slideImage.img}
                src={require(`../../images/${slideImage.img}.jpg`)}
              />
            </SwiperSlide>
          </div>
        </div>
      ))}
    </Swiper>
  );
});
