import styles from './top-menu.module.scss';
import I18n from '../../lib/lingui/i18n';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { InputSelect } from './select.tsx';
import { languagesSelectList, languagesCode } from '../../globals/global-lists.tsx';

export const TopMenu = (props: any) => {
  const [languageDirection, setLanguageDirection] = useState<string>();
  const history = useHistory();

  const menuList: { name: string; page: string }[] = [
    { name: 'Home', page: '' },
    { name: 'Guide', page: 'guide' },
    { name: 'Plans & Pricing', page: 'plans-pricing' },
  ];

  useEffect(() => {
    if (window.location.pathname.includes('he') || window.location.pathname.includes('ar')) {
      setLanguageDirection('rtl');
    } else {
      setLanguageDirection('ltr');
    }
  }, []);

  return (
    <div
      className={styles.topbarMenu}
      style={languageDirection === 'rtl' ? { direction: 'rtl' } : { direction: 'ltr' }}
    >
      <div id="topbar" className={`${styles.topbar}`}>
        <img
          src={
            'https://phonedo-media.fra1.digitaloceanspaces.com/landing-page/widget/widgetIcon.png'
          }
          alt={'widgetIcon'}
          className={`${styles.widgetIcon}`}
        />
        <div className={`${styles.menuSideMobile}`}>
          <img
            src={'https://phonedo-media.fra1.digitaloceanspaces.com/landing-page/widget/close.png'}
            alt={'MenuIcon'}
            className={`${styles.closeIcon}`}
            onClick={() => {
              props.close();
            }}
          />
        </div>
      </div>
      <div id="menuBody" className={`${styles.menuBody}`}>
        {menuList.map((item, i) => (
          <div
            key={`r${i}`}
            className={`${styles.row}`}
            onClick={() => {
              history.push(`/${window.location.pathname.slice(1, 3)}/${item.page}`);
            }}
          >
            <span className={styles.rowTitle}>
              <I18n t={`${item.name}`}></I18n>
            </span>
            <img
              className={styles.rowIcon}
              src={
                'https://phonedo-media.fra1.digitaloceanspaces.com/landing-page/widget/next_arrow.png'
              }
              alt={'next-arrow'}
              style={
                languageDirection === 'rtl'
                  ? { transform: 'scaleX(-1)' }
                  : { transform: 'scaleX(1)' }
              }
            />
          </div>
        ))}
        <div className={`${styles.select}`}>
          <InputSelect
            placeholder={languagesCode[window.location.pathname.slice(1, 3)]}
            options={languagesSelectList}
            setLanguageDirection={setLanguageDirection}
            onSelectLang={props.close}
          ></InputSelect>
        </div>
      </div>

      <div
        id="bottombar"
        style={languageDirection === 'rtl' ? { direction: 'ltr' } : { direction: 'rtl' }}
        className={`${styles.bottombar}`}
      >
        <button className={styles.registerBtn} onClick={() => {}}>
          <I18n t="Register" />
        </button>
      </div>
    </div>
  );
};
