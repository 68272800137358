import { FieldRenderProps } from 'react-final-form';
import styles from './form.module.scss';
import React from 'react';
import Select from 'react-select';

type Props<T> = FieldRenderProps<T, HTMLInputElement>;

export const InputSelect = <T extends string>(props: Props<T>) => {
  const customStyles = {
    control: (provided, state) => ({
      fontFamily: 'Rubik',
      borderRadius: 8,
      textAlign: 'start' as const,
      borderStyle: 'solid',
      borderWidth: 1,
      display: 'flex',
      borderColor: state.isFocused && props.borderColorFocus ? props.borderColorFocus : '#cccccc',
      paddingInlineStart: 8,
      height: '5.4vh',
      color: '#cccccc',
      fontWeight: 500,
      fontSize: 14,

      '@media (max-width: 1800px)': {
        ...styles['@media (max-width: 1800px)'],
      },
      '@media (max-width: 640px)': {
        ...styles['@media (max-width: 640px)'],
        width: 300,
        height: 50,
        marginInlineStart: 10,
        marginTop: 180,
        fontSize: 12,
      },
      '@media (max-width: 1300px)': {
        ...styles['(max-width: 1300px)'],
        borderRadius: 5.1875,
        color: '#ffffff',
        fontWeight: 400,
        fontSize: 12,
      },
    }),
    menu: (base) => ({
      ...base,
      // width:props.menuStyle&& props.menuStyle,
      paddingTop: 0,
      paddingBottom: 0,
      //marginInlineStart: 100,
      //width: 475,
      backgroundColor: '#33354E',
      color: ' #191919',
      borderWidth: 2,
      borderStyle: 'solid',
      borderColor: '#ffffff',
      borderRadius: 10,
      marginInlineStart: '11%',
      width: '77%',

      '@media (max-width: 640px)': {
        ...styles['@media (max-width: 640px)'],

        marginInlineStart: 10,
        width: 310,
      },
      '@media (max-width: 1300px)': {
        ...styles['(max-width: 1300px)'],
        borderRadius: 5.18143,
        color: '#ffffff',
        fontWeight: 400,
        fontSize: 12,
        boxShadow: '3.2384px 7.77215px 20.7257px rgba(26, 26, 26, 0.24)',
      },
    }),
    option: (base, state) => ({
      ...base,
      backgroundColor: state.isSelected
        ? '#000000'
        : state.isFocused
        ? '#41424e'
        : base.backgroundColor,
      color: ' #ffffff',
    }),
  };

  const { meta, input } = props;

  return (
    <>
      <Select
        isSearchable={false}
        styles={customStyles}
        className={styles.selectCountry}
        options={props.options}
        onChange={input.onChange}
        value={props.options.filter((option) => option === input.value)}
        formatOptionLabel={(option, { context }) => {
          return context === 'menu' || !props.showValueInLabel ? option.label : option.value;
        }}
        placeholder={props.placeholder}
        ref={(ref) => {
          props.focusInput ? props.focusInput(ref) : (ref = null);
        }}
      />
      {/* option:hover {
           
             background-color:#000000;} */}
      {(meta.touched && meta.error) ||
        (meta.submitError && <div className="submitError">{meta.error || meta.submitError}</div>)}
    </>
  );
};
