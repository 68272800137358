export var baseUrl = 'https://phonedo-media.fra1.digitaloceanspaces.com/landing-page/widget/';

export const section12List: {
  row: number;
  data: { id: number; name: string; img: string; numAgent: string }[];
}[] = [
  {
    row: 1,
    data: [
      { id: 0, name: '', img: '', numAgent: '' },
      {
        id: 1,
        name: 'Video Games',
        img: baseUrl + 'controller%201.png',
        numAgent: '24,312',
      },
      {
        id: 2,
        name: 'Industrial',
        img: baseUrl + 'crane%201.png',
        numAgent: '6,736',
      },
      {
        id: 3,
        name: 'Fashion',
        img: baseUrl + 'shoe-woman%201.png',
        numAgent: '18,732',
      },
      {
        id: 4,
        name: 'Women',
        img: baseUrl + 'hair-woman%201.png',
        numAgent: '24,441',
      },
    ],
  },
  {
    row: 2,
    data: [
      {
        id: 5,
        name: 'Magazine Subscription',
        img: baseUrl + 'book-open-2%201.png',
        numAgent: '1,987',
      },
      {
        id: 6,
        name: 'Men',
        img: baseUrl + 'hair-man%201.png',
        numAgent: '13,874',
      },
      {
        id: 7,
        name: 'Books',
        img: baseUrl + 'book-bookmark-2%201.png',
        numAgent: '3,771',
      },
      {
        id: 8,
        name: 'Arts',
        img: baseUrl + 'brush%201.png',
        numAgent: '3,444',
      },
      {
        id: 9,
        name: 'Grocery & gourmet food',
        img: baseUrl + 'apple-2%201.png',
        numAgent: '7,822',
      },
    ],
  },
  {
    row: 3,
    data: [
      {
        id: 10,
        name: 'Home',
        img: baseUrl + 'home-3%201.png',
        numAgent: '23,487',
      },
      {
        id: 11,
        name: 'Automotive',
        img: baseUrl + 'car-front%201.png',
        numAgent: '4,901',
      },
      {
        id: 12,
        name: 'Vehicle',
        img: baseUrl + 'bus-front-12%201.png',
        numAgent: '4,733',
      },
      {
        id: 13,
        name: 'Sport & Outdoors',
        img: baseUrl + 'rugby-ball%201.png',
        numAgent: '6,009',
      },
      {
        id: 14,
        name: 'Patio, Lawn & Garden',
        img: baseUrl + 'plant-ground%201.png',
        numAgent: '4,771',
      },
    ],
  },
  {
    row: 4,
    data: [
      {
        id: 15,
        name: 'Health Care',
        img: baseUrl + 'bag-49%201.png',
        numAgent: '15,339',
      },
      {
        id: 16,
        name: 'Luggage & Travel Gear',
        img: baseUrl + 'backpack%201.png',
        numAgent: '8,336',
      },
      {
        id: 17,
        name: 'Education',
        img: baseUrl + 'Icon.png',
        numAgent: '11,871',
      },
      {
        id: 18,
        name: 'Crafts',
        img: baseUrl + 'scissors-dashed%201.png',
        numAgent: '3,775',
      },
      {
        id: 19,
        name: 'Apps',
        img: baseUrl + 'table-layout%201.png',
        numAgent: '8,876',
      },
    ],
  },
  {
    row: 5,
    data: [
      {
        id: 20,
        name: 'Scientific',
        img: baseUrl + 'api%201.png',
        numAgent: '2,143',
      },
      {
        id: 21,
        name: 'Clothing',
        img: baseUrl + 'long-sleeve%201.png',
        numAgent: '26,785',
      },
      {
        id: 22,
        name: 'Finance',
        img: baseUrl + 'round-dollar%201.png',
        numAgent: '6,512',
      },
      {
        id: 23,
        name: 'Beauty',
        img: baseUrl + 'makeup%201.png',
        numAgent: '17,867',
      },
      {
        id: 24,
        name: 'Pet Supplies',
        img: baseUrl + 'dog-leash%201.png',
        numAgent: '31,442',
      },
    ],
  },
  {
    row: 6,
    data: [
      {
        id: 25,
        name: 'Jewelry',
        img: baseUrl + 'diamond%201.png',
        numAgent: '5,464',
      },
      {
        id: 26,
        name: 'Personal Care',
        img: baseUrl + 'hairdresser%201.png',
        numAgent: '4,476',
      },
      {
        id: 27,
        name: 'Shoes',
        img: baseUrl + 'shoe-man%201.png',
        numAgent: '24,326',
      },
      {
        id: 28,
        name: 'Kitchen',
        img: baseUrl + 'oven%201.png',
        numAgent: '5,231',
      },
      {
        id: 29,
        name: 'Electronics',
        img: baseUrl + 'server-rack%201.png',
        numAgent: '9,238',
      },
    ],
  },
  {
    row: 7,
    data: [
      {
        id: 30,
        name: 'Baby',
        img: baseUrl + 'happy-baby%201.png',
        numAgent: '17,345',
      },
      {
        id: 31,
        name: 'Tools & Home improvements',
        img: baseUrl + 'drill%201.png',
        numAgent: '2,778',
      },
      {
        id: 32,
        name: 'Gifts',
        img: baseUrl + 'gift%201.png',
        numAgent: '5,598',
      },
      {
        id: 33,
        name: 'Cell Phones & Accessories',
        img: baseUrl + 'phone-camera-front%201.png',
        numAgent: '19,445',
      },
      {
        id: 34,
        name: 'Toys',
        img: baseUrl + 'dice%201.png',
        numAgent: '8,827',
      },
    ],
  },
  {
    row: 8,
    data: [
      {
        id: 35,
        name: 'Adults',
        img: baseUrl + 'weed%201.png',
        numAgent: '977',
      },
      {
        id: 36,
        name: 'Appliances',
        img: baseUrl + 'audio-jack%201.png',
        numAgent: '5,634',
      },
      {
        id: 37,
        name: 'Computers',
        img: baseUrl + 'vintage-computer%201.png',
        numAgent: '23,994',
      },
      {
        id: 38,
        name: 'Games',
        img: baseUrl + 'handheld-console%201.png',
        numAgent: '18,429',
      },
      {
        id: 39,
        name: 'Digital Music',
        img: baseUrl + 'music-cloud%201.png',
        numAgent: '23,776',
      },
    ],
  },
];

export const section12ListMobile: {
  row: number;
  data: { id: number; name: string; img: string; numAgent: string }[];
}[] = [
  {
    row: 1,
    data: [
      {
        id: 0,
        name: 'Video Games',
        img: baseUrl + 'controller%201.png',
        numAgent: '24,312',
      },
      {
        id: 1,
        name: 'Industrial',
        img: baseUrl + 'crane%201.png',
        numAgent: '6,736',
      },
    ],
  },
  {
    row: 2,
    data: [
      {
        id: 2,
        name: 'Fashion',
        img: baseUrl + 'shoe-woman%201.png',
        numAgent: '18,732',
      },
      {
        id: 3,
        name: 'Women',
        img: baseUrl + 'hair-woman%201.png',
        numAgent: '24,441',
      },
    ],
  },
  {
    row: 3,
    data: [
      {
        id: 4,
        name: 'Magazine Subscription',
        img: baseUrl + 'book-open-2%201.png',
        numAgent: '1,987',
      },
      {
        id: 5,
        name: 'Men',
        img: baseUrl + 'hair-man%201.png',
        numAgent: '13,874',
      },
    ],
  },
  {
    row: 4,
    data: [
      {
        id: 6,
        name: 'Books',
        img: baseUrl + 'book-bookmark-2%201.png',
        numAgent: '3,771',
      },
      {
        id: 7,
        name: 'Arts',
        img: baseUrl + 'brush%201.png',
        numAgent: '3,444',
      },
    ],
  },
  {
    row: 5,
    data: [
      {
        id: 8,
        name: 'Grocery & gourmet food',
        img: baseUrl + 'apple-2%201.png',
        numAgent: '7,822',
      },
      {
        id: 9,
        name: 'Home',
        img: baseUrl + 'home-3%201.png',
        numAgent: '23,487',
      },
    ],
  },
  {
    row: 6,
    data: [
      {
        id: 10,
        name: 'Automotive',
        img: baseUrl + 'car-front%201.png',
        numAgent: '4,901',
      },
      {
        id: 11,
        name: 'Vehicle',
        img: baseUrl + 'bus-front-12%201.png',
        numAgent: '4,733',
      },
    ],
  },
  {
    row: 7,
    data: [
      {
        id: 12,
        name: 'Sport & Outdoors',
        img: baseUrl + 'rugby-ball%201.png',
        numAgent: '6,009',
      },
      {
        id: 13,
        name: 'Patio, Lawn & Garden',
        img: baseUrl + 'plant-ground%201.png',
        numAgent: '4,771',
      },
    ],
  },
  {
    row: 8,
    data: [
      {
        id: 14,
        name: 'Health Care',
        img: baseUrl + 'bag-49%201.png',
        numAgent: '15,339',
      },
      {
        id: 15,
        name: 'Luggage & Travel Gear',
        img: baseUrl + 'backpack%201.png',
        numAgent: '8,336',
      },
    ],
  },
  {
    row: 9,
    data: [
      {
        id: 16,
        name: 'Education',
        img: baseUrl + 'Icon.png',
        numAgent: '11,871',
      },
      {
        id: 17,
        name: 'Crafts',
        img: baseUrl + 'scissors-dashed%201.png',
        numAgent: '3,775',
      },
    ],
  },
  {
    row: 10,
    data: [
      {
        id: 18,
        name: 'Apps',
        img: baseUrl + 'table-layout%201.png',
        numAgent: '8,876',
      },
      {
        id: 19,
        name: 'Scientific',
        img: baseUrl + 'api%201.png',
        numAgent: '2,143',
      },
    ],
  },
  {
    row: 11,
    data: [
      {
        id: 20,
        name: 'Clothing',
        img: baseUrl + 'long-sleeve%201.png',
        numAgent: '26,785',
      },
      {
        id: 21,
        name: 'Finance',
        img: baseUrl + 'round-dollar%201.png',
        numAgent: '6,512',
      },
    ],
  },
  {
    row: 12,
    data: [
      {
        id: 22,
        name: 'Beauty',
        img: baseUrl + 'makeup%201.png',
        numAgent: '17,867',
      },
      {
        id: 23,
        name: 'Pet Supplies',
        img: baseUrl + 'dog-leash%201.png',
        numAgent: '31,442',
      },
    ],
  },
  {
    row: 13,
    data: [
      {
        id: 24,
        name: 'Jewelry',
        img: baseUrl + 'diamond%201.png',
        numAgent: '5,464',
      },
      {
        id: 25,
        name: 'Personal Care',
        img: baseUrl + 'hairdresser%201.png',
        numAgent: '4,476',
      },
    ],
  },
  {
    row: 14,
    data: [
      {
        id: 26,
        name: 'Shoes',
        img: baseUrl + 'shoe-man%201.png',
        numAgent: '24,326',
      },
      {
        id: 27,
        name: 'Kitchen',
        img: baseUrl + 'oven%201.png',
        numAgent: '5,231',
      },
    ],
  },
  {
    row: 15,
    data: [
      {
        id: 28,
        name: 'Electronics',
        img: baseUrl + 'server-rack%201.png',
        numAgent: '9,238',
      },
      {
        id: 29,
        name: 'Baby',
        img: baseUrl + 'happy-baby%201.png',
        numAgent: '17,345',
      },
    ],
  },
  {
    row: 16,
    data: [
      {
        id: 30,
        name: 'Tools & Home improvements',
        img: baseUrl + 'drill%201.png',
        numAgent: '2,778',
      },
      {
        id: 31,
        name: 'Gifts',
        img: baseUrl + 'gift%201.png',
        numAgent: '5,598',
      },
    ],
  },
  {
    row: 17,
    data: [
      {
        id: 32,
        name: 'Cell Phones & Accessories',
        img: baseUrl + 'phone-camera-front%201.png',
        numAgent: '19,445',
      },
      {
        id: 33,
        name: 'Toys',
        img: baseUrl + 'dice%201.png',
        numAgent: '8,827',
      },
    ],
  },
  {
    row: 18,
    data: [
      {
        id: 34,
        name: 'Adults',
        img: baseUrl + 'weed%201.png',
        numAgent: '977',
      },
      {
        id: 35,
        name: 'Appliances',
        img: baseUrl + 'audio-jack%201.png',
        numAgent: '5,634',
      },
    ],
  },
  {
    row: 19,
    data: [
      {
        id: 36,
        name: 'Computers',
        img: baseUrl + 'vintage-computer%201.png',
        numAgent: '23,994',
      },
      {
        id: 37,
        name: 'Games',
        img: baseUrl + 'handheld-console%201.png',
        numAgent: '18,429',
      },
    ],
  },
  {
    row: 20,
    data: [
      {
        id: 38,
        name: 'Digital Music',
        img: baseUrl + 'music-cloud%201.png',
        numAgent: '23,776',
      },
      { id: 39, name: '', img: '', numAgent: '' },
    ],
  },
];

export const languagesSelectList: { label: string; value: string }[] = [
  { label: 'עברית', value: 'he' },
  { label: 'English', value: 'en' },
  { label: 'اللغة العربية', value: 'ar' },
  { label: 'Magyar', value: 'hu' },
  { label: 'Ελληνικά', value: 'gr' },
  { label: 'Русский', value: 'ru' },
  { label: 'Español', value: 'es' },
  { label: 'Français', value: 'fr' },
  { label: 'Deutsch', value: 'de' },
  { label: 'Nederlands', value: 'nl' },
];

export const languagesCode = {
  he: 'עברית',
  en: 'English',
  ar: 'اللغة العربية',
  hu: 'Magyar',
  gr: 'Ελληνικά',
  ru: 'Русский',
  es: 'Español',
  fr: 'Français',
  de: 'Deutsch',
  nl: 'Nederlands',
};
