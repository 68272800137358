import { FieldRenderProps } from 'react-final-form';
import styles from './form.module.scss';
import React from 'react';

type Props<T> = FieldRenderProps<T, HTMLInputElement>;

export const InputTextArea = <T extends string | number>(props: Props<T>) => {
  const { meta, input } = props;
  const languageDirection =
    window.location.pathname.startsWith('/he') || window.location.pathname.startsWith('/ar')
      ? 'rtl'
      : 'ltr';

  return (
    <>
      <div className={props.inputClass ? props.inputClass : styles.inputText}>
        {input.value && (
          <div
            className={
              languageDirection === 'rtl'
                ? `${styles.smallPlaceholder} ${styles.smallPlaceholderRtl}`
                : styles.smallPlaceholder
            }
          >
            {props.placeholder}
          </div>
        )}
        <textarea
          value={input.value}
          placeholder={props.placeholder}
          onChange={input.onChange}
          className={props.placeholderSelect ? props.placeholderSelect : styles.placeholderSelect}
          name="w3review"
          rows={5}
          cols={50}
        />
        {props.icon}
      </div>
      {(meta.touched && meta.error) ||
        (meta.submitError && (
          <div className={styles.submitError}>{meta.error || meta.submitError}</div>
        ))}
    </>
  );
};
