import styles from './select.module.scss';
import React from 'react';
import Dropdown from 'react-dropdown';
import { useHistory } from 'react-router';
import { languagesCode } from '../../globals/global-lists.tsx';

export const InputSelect = (props: any) => {
  const history = useHistory();
  const setLanguageDirection = props.setLanguageDirection;

  const onChangeLang = (item) => {
    history.push(`/${item.value}/${window.location.pathname.slice(4)}`);
    if (item.value === 'he' || item.value === 'ar') {
      setLanguageDirection('rtl');
    } else {
      setLanguageDirection('ltr');
    }
    props.onSelectLang();
  };

  return (
    <>
      <Dropdown
        controlClassName={`${styles.control}`}
        menuClassName={`${styles.menu}`}
        placeholderClassName={`${styles.placeholder}`}
        options={props.options}
        onChange={(item) => {
          onChangeLang(item);
        }}
        value={languagesCode[window.location.pathname.slice(1, 3)]}
        placeholder={props.placeholder}
      />
    </>
  );
};
