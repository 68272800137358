import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import MagicSliderDots from 'react-magic-slider-dots';
import 'react-magic-slider-dots/dist/magic-dots.css';
import styles from './landing-page.module.scss';
import { ConectUsForm } from '../../stores/landing-page-store';
import 'react-slideshow-image/dist/styles.css';
import { PageTitle } from '../ui/page-title/page-title.tsx';
import { SubTitle } from '../ui/sub-title/sub-title.tsx';
import { InputField } from '../ui/input-field.tsx';
import { InputSelectCountry } from '../ui/input-selectCountry.tsx';
import { InputSelect } from '../ui/input-select.tsx';
import { InputTextArea } from '../ui/input-textarea.tsx';
import { Field, Form } from 'react-final-form';
import { AiFillCloseCircle } from 'react-icons/ai';
import { AiOutlineArrowDown } from 'react-icons/ai';
import { AiOutlineArrowUp } from 'react-icons/ai';
import minimal from '../../images/minimal.gif';
import characters from '../../images/characters.json';
import talkChat from '../../images/talkChat.json';
import thumbupLightGreen from '../../images/thumbupLightGreen.json';
import thumbupGreen from '../../images/thumbupGreen.json';
import thumbupPink from '../../images/thumbupPink.json';
import thumbupLightBlue from '../../images/thumbupLightBlue.json';
import thumbupYellow from '../../images/thumbupYellow.json';
import thumbupLightPink from '../../images/thumbupLightPink.json';
import thumbupBlack from '../../images/thumbupBlack.json';
import thumbupRed from '../../images/thumbupRed.json';
import thumbupBej from '../../images/thumbupBej.json';
import lottie from 'lottie-web';
import React, { useState, useCallback, useEffect } from 'react';
import I18n from '../../lib/lingui/i18n';
import { LanguageMenu } from '../../lib/final-form/languageMenu.tsx';
import { SliderSection } from '../../lib/final-form/sliderSection.tsx';
import CookieConsent from 'react-cookie-consent';
import { useHistory } from 'react-router';
import { section12List, section12ListMobile } from '../../globals/global-lists.tsx';
import { TopMenu } from '../../lib/final-form/top-menu.tsx';
import ReactPlayer from 'react-player/youtube';
import { countryCodeList } from '../globals/professional-information.tsx';
// @ts-ignore
import { useStore } from '../../stores/use-store.tsx';
import { ReasonForRequest } from '../globals/professional-information.tsx';
import { isMobile } from 'react-device-detect';

export const LandingPage = (props: any) => {
  const { landingPageStore } = useStore();
  const [sectionsArray] = useState<Array<string>>([
    'section1',
    'section2',
    'section3',
    'section4',
    'section5',
    'section6',
    'section7',
    'section8',
    'section9',
    'section10',
    'section11',
    'section12',
    'section13',
    'section14',
    'section15',
  ]);
  const [closeTopBar, setCloseTopBar] = useState(false);
  const currentSection = React.useRef<number>(1);
  const [allDomains, setAllDomains] = useState<boolean>(false);
  const [menu, setMenu] = useState<boolean>(false);
  const [finalSection, setFinalSection] = useState<boolean>(false);
  // const [deviceType, setDeviceType] = useState<string>();
  const [languageDirection, setLanguageDirection] = useState<string>();
  const [language, setLanguage] = useState<string>();
  const [idxCard, setIdxCard] = useState<any>(0);
  const [videoPlay, setVideoPlay] = useState<boolean>(false);
  const history = useHistory();
  const { location } = props;

  // const { shopifyPlatformAccessId } = useParams();
  // const { email } = useParams();
  // const { companyName } = useParams();
  // console.log(shopifyPlatformAccessId)
  // const redirectUrl = 'https://full.bpo.do/auth/sign-up?email' + email + '&companyName' + companyName + '&shopifyPlatformAccessId' + shopifyPlatformAccessId;
  // const { location } = props;
  // function redirect() {
  //   window.location.href = redirectUrl;
  // }
  // var responseErr = '';
  const callbackObserve = useCallback(
    (entries: any, observer: any) => {
      entries.forEach((entry: any) => {
        if (entry.target) {
          if (entry.isIntersecting) {
            const currentSectionId = Number(entry.target.id.match(/\d+/));
            if (currentSectionId !== currentSection.current) {
              currentSection.current = currentSectionId;
            }
            if (currentSection.current === 16 && finalSection === false) {
              setFinalSection(true);
            } else if (currentSection.current !== 16) {
              setFinalSection(false);
            }
          }
        }
      });
    },
    [finalSection]
  );
  const sectionInViewport = (observer: IntersectionObserver) => {
    [
      'section1',
      'section2',
      'section3',
      'section4',
      'section5',
      'section6',
      'section7',
      'section8',
      'section9',
      'section10',
      'section11',
      'section12',
      'section13',
      'section14',
      'section15',
    ].forEach((section) => {
      const divSction = document.getElementById(section);
      if (divSction) {
        observer.observe(divSction);
      }
    });
  };

  useEffect(() => {
    if (location.state) {
      console.log('location.state', location.state);
      dropNextPage(8);
    }
    // let typeOfDevice = navigator.userAgent.toLocaleLowerCase();
    // if (/window/i.test(typeOfDevice)) {
    //   setDeviceType('desktop')
    // }
    // else if (/android/i.test(typeOfDevice)) {
    //   setDeviceType('android')
    // }
    // else {
    //   setDeviceType('ios');

    // }
    if (window.location.pathname.includes('he') || window.location.pathname.includes('ar')) {
      setLanguageDirection('rtl');
    } else {
      setLanguageDirection('ltr');
    }
    const observer = new IntersectionObserver(callbackObserve, {
      threshold: [0.2],
    });
    sectionInViewport(observer);
    const unobserve = (arr: Array<string>, obs: any) => {
      arr.forEach((item) => {
        const divItem = document.getElementById(item);
        if (divItem) {
          obs.unobserve(divItem);
        }
      });
      obs.disconnect();
    };

    window.addEventListener('beforeunload', function () {
      unobserve(sectionsArray, observer);
    });
    //eslint-disable-next-line
  }, [sectionsArray, callbackObserve]);
  React.useEffect(() => {
    lottie.loadAnimation({
      container: document.querySelector('#characters') as HTMLElement,
      animationData: characters,
    });
    lottie.loadAnimation({
      container: document.querySelector('#talkChat') as HTMLElement,
      animationData: talkChat,
    });
  }, []);

  React.useEffect(() => {
    lottie.loadAnimation({
      container: document.querySelector('#thumbupLightGreen2') as HTMLElement,
      animationData: thumbupLightGreen,
    });

    lottie.loadAnimation({
      container: document.querySelector('#thumbupGreen1') as HTMLElement,
      animationData: thumbupGreen,
    });

    lottie.loadAnimation({
      container: document.querySelector('#thumbupLightBlue1') as HTMLElement,
      animationData: thumbupLightBlue,
    });

    lottie.loadAnimation({
      container: document.querySelector('#thumbupYellow1') as HTMLElement,
      animationData: thumbupYellow,
    });

    lottie.loadAnimation({
      container: document.querySelector('#thumbupLightPink') as HTMLElement,
      animationData: thumbupLightPink,
    });
    lottie.loadAnimation({
      container: document.querySelector('#thumbupBej') as HTMLElement,
      animationData: thumbupBej,
    });
    lottie.loadAnimation({
      container: document.querySelector('#thumbupPink') as HTMLElement,
      animationData: thumbupPink,
    });
    lottie.loadAnimation({
      container: document.querySelector('#thumbupBlack1') as HTMLElement,
      animationData: thumbupBlack,
    });

    lottie.loadAnimation({
      container: document.querySelector('#thumbupRed') as HTMLElement,
      animationData: thumbupRed,
    });
    lottie.loadAnimation({
      container: document.querySelector('#thumbupPink2') as HTMLElement,
      animationData: thumbupRed,
    });
    lottie.loadAnimation({
      container: document.querySelector('#thumbupBlack2') as HTMLElement,
      animationData: thumbupBlack,
    });
    lottie.loadAnimation({
      container: document.querySelector('#thumbupYellow2') as HTMLElement,
      animationData: thumbupYellow,
    });
    lottie.loadAnimation({
      container: document.querySelector('#thumbupLightBlue2') as HTMLElement,
      animationData: thumbupLightBlue,
    });
    lottie.loadAnimation({
      container: document.querySelector('#thumbupGreen2') as HTMLElement,
      animationData: thumbupGreen,
    });
    lottie.loadAnimation({
      container: document.querySelector('#thumbupLightGreen1') as HTMLElement,
      animationData: thumbupLightGreen,
    });
    lottie.loadAnimation({
      container: document.querySelector('#thumbupPink1') as HTMLElement,
      animationData: thumbupRed,
    });
    lottie.loadAnimation({
      container: document.querySelector('#thumbupGreen3') as HTMLElement,
      animationData: thumbupGreen,
    });
  }, [language, menu]);

  // React.useEffect(() => {
  //   lottie.loadAnimation({
  //     container: document.querySelector("#section15") as HTMLElement,
  //     animationData: section14,
  //   });
  // }, []);

  const card: {
    id: number;
    img: string;
    title: string;
    subTitle: string;
    feedback: string;
    size: string;
  }[] = [
    {
      id: 1,
      img: 'law',
      title: 'Bruce Cinner',
      subTitle: 'Law Office',
      feedback:
        'with results we do not argue Since I started working with the service sales are growing day by day consistently and customers are satisfied',
      size: '',
    },
    {
      id: 2,
      img: 'trainer',
      title: 'Mark Dicer ',
      subTitle: 'Personal Trainer',
      feedback:
        'I thought I would work alone and save money The business almost collapsed The amazing service saved my life And the profits do not stop climbing',
      size: '',
    },
    {
      id: 3,
      img: 'nail',
      title: 'Eva Demery',
      subTitle: 'Nail builder',
      feedback:
        'For me it was very important to give good service after the purchase Satisfied customers bring in a livelihood The service is perfect How do I know Because I have peace of mind',
      size: '',
    },
  ];
  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    centerMode: true,
    className: 'center',
    appendDots: (dots) => {
      return <MagicSliderDots dots={dots} numDotsToShow={card.length} dotWidth={30} />;
    },
    beforeChange: (index) => {
      if (index + 1 < card.length) {
        setIdxCard(index + 1);
      } else {
        setIdxCard(0);
      }
    },
  };
  const experience: {
    id: number;
    gif: string;
    details: string;
    sentence: string;
  }[] = [
    {
      id: 1,
      gif: 'settings',
      details: 'Connect to System',
      sentence:
        'We connect to your stores back end - giving us full access to inventory and all information about products and orders',
    },
    {
      id: 2,
      gif: 'customer',
      details: 'Connect to Customer',
      sentence:
        'When a new customer enters the site - FULL immediately identifies language and location connecting them to the right rep for a fully-personalized real time experience',
    },
    {
      id: 3,
      gif: 'review',
      details: 'Connect the Dots',
      sentence:
        'Our reps are trained to answer questions help with returns change orders check inventory make recommendations and increase conversion rates cart size and repeat business exponentially',
    },
  ];
  const beFully: { id: number; details: string; imgFully: string }[] = [
    { id: 1, details: 'Dedicated', imgFully: 'handshakeDeal' },
    { id: 2, details: 'Professional', imgFully: 'rocket' },
    { id: 3, details: 'Fluent', imgFully: 'omnichannel' },
    { id: 5, details: 'Present', imgFully: 'fireAlarm' },
    { id: 7, details: 'Covered', imgFully: 'carousel' },
    { id: 9, details: 'Available', imgFully: 'clock' },
    { id: 10, details: 'Committed', imgFully: 'handshake' },
    { id: 11, details: 'Concentrated', imgFully: 'user' },
  ];
  const votes: {
    id: number;
    text: string;
    color: string;
    colorBackground: string;
    gif: string;
  }[] = [
    {
      id: 1,
      text: 'Sales',
      color: '#42C6A5',
      colorBackground: 'rgba(66, 198, 165, 0.3)',
      gif: 'thumbupLightGreen1',
    },
    {
      id: 2,
      text: 'Returns',
      color: '#E9F6FF',
      colorBackground: 'rgba(100, 190, 225, 0.3)',
      gif: 'thumbupLightBlue1',
    },
    {
      id: 3,
      text: 'Service',
      color: '#E9F6FF',
      colorBackground: 'rgba(0, 0, 0, 0.3)',
      gif: 'thumbupBlack1',
    },
    {
      id: 4,
      text: 'Tech Support',
      color: '#E9F6FF',
      colorBackground: 'rgba(100, 190, 225, 0.3)',
      gif: 'thumbupLightBlue2',
    },
    {
      id: 5,
      text: 'Surveys',
      color: '#45745E',
      colorBackground: 'rgba(140, 198, 171, 0.3)',
      gif: 'thumbupGreen1',
    },
    {
      id: 6,
      text: 'Gift Cards',
      color: '#EEFFEE',
      colorBackground: 'rgba(229, 193, 132, 0.3)',
      gif: 'thumbupBej',
    },
    {
      id: 7,
      text: 'Tracking',
      color: '#FCF1FF',
      colorBackground: 'rgba(255, 98, 230,0.3)',
      gif: 'thumbupPink1',
    },
    {
      id: 8,
      text: 'Pre Orders',
      color: '#FCF1FF',
      colorBackground: 'rgba(51, 53, 78, 0.3)',
      gif: 'thumbupBlack2',
    },
    {
      id: 9,
      text: 'Memberships',
      color: '#45745E',
      colorBackground: 'rgba(69, 116, 94, 0.3)',
      gif: 'thumbupGreen2',
    },
    {
      id: 10,
      text: 'Modifications',
      color: '#EEFFEE',
      colorBackground: 'rgba(251, 179, 68, 0.3)',
      gif: 'thumbupYellow1',
    },
    {
      id: 11,
      text: 'information',
      color: '#EEFFEE',
      colorBackground: 'rgba(66, 198, 165, 0.3)',
      gif: 'thumbupLightGreen2',
    },
    {
      id: 12,
      text: 'Carts Edit',
      color: '#FCF1FF',
      colorBackground: 'rgba(252, 38, 38, 0.3)',
      gif: 'thumbupRed',
    },
    {
      id: 13,
      text: 'After order',
      color: '#FC9BA1',
      colorBackground: 'rgba(252, 155, 161, 0.3)',
      gif: 'thumbupLightPink',
    },
    {
      id: 14,
      text: 'Meetings',
      color: '#E2FAFF',
      colorBackground: 'rgba(255, 98, 230, 0.3)',
      gif: 'thumbupPink2',
    },
    {
      id: 15,
      text: 'Billing',
      color: '#E2FAFF',
      colorBackground: 'rgba(251, 179, 68, 0.3)',
      gif: 'thumbupYellow2',
    },
    {
      id: 16,
      text: 'Inventory',
      color: '#E2FAFF',
      colorBackground: 'rgba(140, 198, 171, 0.3)',
      gif: 'thumbupGreen3',
    },
  ];
  const section7Map: { id: number; sentence: string }[] = [
    { id: 1, sentence: 'No Minimum period' },
    { id: 2, sentence: 'No Minimum Minutes' },
    { id: 3, sentence: 'No Setup Fee' },
    { id: 4, sentence: 'No Monthly Cost' },
  ];
  const integrated: {
    id: number;
    integratedName: string;
    integratedImg: string;
    navigateUrl: string;
  }[] = [
    {
      id: 1,
      integratedName: 'Elementor',
      integratedImg: 'elementorLogo',
      navigateUrl: '',
    },
    {
      id: 2,
      integratedName: 'volusion',
      integratedImg: 'colutionLogo',
      navigateUrl: '',
    },
    {
      id: 3,
      integratedName: 'Quick eSelling',
      integratedImg: 'quickeSelling',
      navigateUrl: '',
    },
    {
      id: 4,
      integratedName: 'GoDaddy',
      integratedImg: 'goLogo',
      navigateUrl: '',
    },
    {
      id: 5,
      integratedName: 'yo!kart',
      integratedImg: 'yolkartLogo',
      navigateUrl: '',
    },
    {
      id: 6,
      integratedName: 'Template Monster',
      integratedImg: 'templateLogo',
      navigateUrl: '',
    },
    // { "id": 7, "integratedName": "Magento Commerce","integratedImg":"magentLogo" },
    {
      id: 8,
      integratedName: '3dcart',
      integratedImg: 'threedLlogo',
      navigateUrl: '',
    },
    {
      id: 9,
      integratedName: 'Commerce Cloud',
      integratedImg: 'cloud',
      navigateUrl: '',
    },
    {
      id: 10,
      integratedName: 'My Dukaan',
      integratedImg: 'myLogo',
      navigateUrl: '',
    },
    // { "id": 11, "integratedName": "Shopify","integratedImg":"shopifyLogo" },
    {
      id: 12,
      integratedName: 'Magento',
      integratedImg: 'magentoLogo',
      navigateUrl: '',
    },
    {
      id: 13,
      integratedName: 'Squarespace',
      integratedImg: 'squarespaceLogo',
      navigateUrl: '',
    },
    {
      id: 14,
      integratedName: 'WIX',
      integratedImg: 'wixLogo',
      navigateUrl: 'https://www.wix.com/app-market/full-do-support-widget',
    },
  ];
  // const [currentFruit, setCurrentFruit] = useState('oranges')

  // const changeFruit = (newFruit) => {
  //   setCurrentFruit(newFruit)
  // }

  const dropNextPage = useCallback((indexOfSection?: number) => {
    let toSection;
    if (currentSection.current === 102) {
      currentSection.current = 10;
    }
    if (indexOfSection !== null && indexOfSection !== undefined) {
      toSection = 'section'.concat((indexOfSection + 1).toString());
      currentSection.current = indexOfSection + 1;
      setFinalSection(false);
    } else {
      currentSection.current = currentSection.current + 1;
      toSection = 'section'.concat(Number(currentSection.current).toString());
    }

    document.getElementById(toSection)?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }, []);

  const closeMenu = () => {
    if (window.location.pathname.includes('he') || window.location.pathname.includes('ar')) {
      setLanguageDirection('rtl');
    } else {
      setLanguageDirection('ltr');
    }
    setMenu(false);
  };
  // const integrated1: { id: number, integratedName: string, integratedImg: string }[] = [
  //   { "id": 1, "integratedName": "WOO COMMERCE", "integratedImg": "wooLogo" },
  //   { "id": 2, "integratedName": "", "integratedImg": "" },
  //   { "id": 3, "integratedName": "Magento Commerce", "integratedImg": "magentLogo" },
  //   { "id": 4, "integratedName": "Shopify", "integratedImg": "shopifyLogo" },
  // ];
  // const integrated2: { id: number, integratedName: string, integratedImg: string }[] = [
  //   { "id": 1, "integratedName": "WOO COMMERCE", "integratedImg": "wooLogo" },
  //   { "id": 2, "integratedName": "", "integratedImg": "" },
  //   { "id": 3, "integratedName": "Magento Commerce", "integratedImg": "magentLogo" },
  //   { "id": 4, "integratedName": "Shopify", "integratedImg": "shopifyLogo" },
  // ];

  return (
    <div>
      <CookieConsent
        location="bottom"
        buttonText={I18n.getTranslation(location, 'Accept')}
        cookieName="full.do_cookies"
        style={{
          background: '#2B373B',
          direction: languageDirection === 'rtl' ? 'rtl' : 'ltr',
          zIndex: '2',
        }}
        buttonStyle={{ background: '#42c6a5', fontSize: '13px' }}
        expires={150}
        overlay={true}
      >
        <span>
          <I18n t="We use cookies and other tracking technologies to improve your browsing experience on our website, to show you personalized content and targeted ads, to analyze our website traffic, and to understand where our visitors are coming from" />{' '}
        </span>
        <span
          onClick={() => {
            history.push(`/${window.location.pathname.slice(1, 3)}/privacy-policy`);
          }}
        >
          <u>
            <I18n t="Privacy Policy" /> <I18n t="Click here" />
          </u>
        </span>
      </CookieConsent>
      {menu ? (
        <TopMenu close={closeMenu} />
      ) : (
        <div>
          {!closeTopBar && (
            <div
              id="topbar"
              className={
                languageDirection === 'rtl'
                  ? styles.topbar.concat(' ').concat(styles.topbarRtl)
                  : styles.topbar
              }
            >
              {isMobile === false ? (
                <>
                  {' '}
                  <div className={styles.topbarSide}>
                    <img
                      className={
                        languageDirection === 'rtl'
                          ? styles.iconTopBar.concat(' ').concat(styles.iconTopBarRtl)
                          : styles.iconTopBar
                      }
                      src={
                        'https://phonedo-media.fra1.digitaloceanspaces.com/landing-page/widget/arrow-up.png'
                      }
                      alt="arrowUp"
                    />
                    <span className={styles.headerTopBar}>
                      <I18n t="Sales"></I18n> {languageDirection === 'rtl' ? '500%+' : '+500%'}
                    </span>
                    <img
                      className={
                        languageDirection === 'rtl'
                          ? styles.iconTopBar.concat(' ').concat(styles.iconTopBarRtl)
                          : styles.iconTopBar
                      }
                      src={
                        'https://phonedo-media.fra1.digitaloceanspaces.com/landing-page/widget/arrow-down.png'
                      }
                      alt="arrowDown"
                    />
                    <span className={styles.headerTopBar}>
                      <I18n t="Returns"></I18n> {languageDirection === 'rtl' ? '95%-' : '-95%'}{' '}
                    </span>
                    <img
                      className={
                        languageDirection === 'rtl'
                          ? styles.iconTopBar.concat(' ').concat(styles.iconTopBarRtl)
                          : styles.iconTopBar
                      }
                      src={
                        'https://phonedo-media.fra1.digitaloceanspaces.com/landing-page/widget/arrow-up.png'
                      }
                      alt="arrowUp"
                    />
                    <span className={styles.headerTopBar}>
                      <I18n t="Margins"></I18n> {languageDirection === 'rtl' ? '35%+' : '+35%'}
                    </span>
                    <img
                      className={
                        languageDirection === 'rtl'
                          ? styles.iconTopBar.concat(' ').concat(styles.iconTopBarRtl)
                          : styles.iconTopBar
                      }
                      src={
                        'https://phonedo-media.fra1.digitaloceanspaces.com/landing-page/widget/star.png'
                      }
                      alt="star"
                    />
                    <span className={styles.headerTopBar}>
                      <I18n t="Reviews"></I18n> 5
                    </span>
                    <img
                      className={
                        languageDirection === 'rtl'
                          ? styles.iconTopBar.concat(' ').concat(styles.iconTopBarRtl)
                          : styles.iconTopBar
                      }
                      src={
                        'https://phonedo-media.fra1.digitaloceanspaces.com/landing-page/widget/chargebacks.png'
                      }
                      alt="star"
                    />
                    <span className={styles.headerTopBar}>
                      <I18n t="Chargebacks"></I18n> 0%
                    </span>
                  </div>
                  <div className={styles.topbarSide}>
                    <img
                      className={
                        languageDirection === 'rtl' ? styles.closeIconRtl : styles.closeIcon
                      }
                      src={
                        'https://phonedo-media.fra1.digitaloceanspaces.com/landing-page/widget/close.png'
                      }
                      alt="close"
                      onClick={() => {
                        setCloseTopBar(true);
                      }}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className={styles.topbarSide} style={{ paddingInlineStart: '28px' }}>
                    <div className={styles.row}>
                      <img
                        className={
                          languageDirection === 'rtl'
                            ? styles.iconTopBar.concat(' ').concat(styles.iconTopBarRtl)
                            : styles.iconTopBar
                        }
                        src={
                          'https://phonedo-media.fra1.digitaloceanspaces.com/landing-page/widget/arrow-down.png'
                        }
                        alt="arrowDown"
                      />
                      <span className={styles.headerTopBar}>
                        <I18n t="Returns"></I18n> %95-
                      </span>
                    </div>
                    <div className={styles.row}>
                      <img
                        className={
                          languageDirection === 'rtl'
                            ? styles.iconTopBar.concat(' ').concat(styles.iconTopBarRtl)
                            : styles.iconTopBar
                        }
                        src={
                          'https://phonedo-media.fra1.digitaloceanspaces.com/landing-page/widget/arrow-up.png'
                        }
                        alt="arrowUp"
                      />
                      <span className={styles.headerTopBar}>
                        <I18n t="Sales"></I18n> %500+
                      </span>
                    </div>
                    <div className={styles.row}>
                      <img
                        className={
                          languageDirection === 'rtl'
                            ? styles.iconTopBar.concat(' ').concat(styles.iconTopBarRtl)
                            : styles.iconTopBar
                        }
                        src={
                          'https://phonedo-media.fra1.digitaloceanspaces.com/landing-page/widget/chargebacks.png'
                        }
                        alt="arrowUp"
                      />
                      <span className={styles.headerTopBar}>
                        <I18n t="Chargebacks"></I18n> 0%
                      </span>
                    </div>
                  </div>
                  <div className={styles.topbarSide}>
                    <div className={styles.row}>
                      <img
                        className={
                          languageDirection === 'rtl'
                            ? styles.iconTopBar.concat(' ').concat(styles.iconTopBarRtl)
                            : styles.iconTopBar
                        }
                        src={
                          'https://phonedo-media.fra1.digitaloceanspaces.com/landing-page/widget/arrow-up.png'
                        }
                        alt="arrowUp"
                      />
                      <span className={styles.headerTopBar}>
                        <I18n t="Margins"></I18n> %35+
                      </span>
                    </div>
                    <div className={styles.row}>
                      <img
                        className={
                          languageDirection === 'rtl'
                            ? styles.iconTopBar.concat(' ').concat(styles.iconTopBarRtl)
                            : styles.iconTopBar
                        }
                        src={
                          'https://phonedo-media.fra1.digitaloceanspaces.com/landing-page/widget/star.png'
                        }
                        alt="star"
                      />
                      <span className={styles.headerTopBar}>
                        <I18n t="Reviews"></I18n> 5
                      </span>
                    </div>
                  </div>
                  <div className={styles.topbarSide}>
                    <img
                      className={
                        languageDirection === 'rtl' ? styles.closeIconRtl : styles.closeIcon
                      }
                      src={
                        'https://phonedo-media.fra1.digitaloceanspaces.com/landing-page/widget/close.png'
                      }
                      alt="close"
                      onClick={() => {
                        setCloseTopBar(true);
                      }}
                    />
                  </div>
                </>
              )}
            </div>
          )}
          {isMobile === false ? (
            <div
              style={languageDirection === 'rtl' ? { direction: 'rtl' } : { direction: 'ltr' }}
              className={`${styles.menuTopBar} ${closeTopBar ? styles.closeTopBarMenu : ''}`}
            >
              <div
                className={`${styles.menuSide} ${
                  languageDirection === 'rtl' ? styles.menuSideRtl : ''
                }`}
              >
                <img
                  src={
                    'https://phonedo-media.fra1.digitaloceanspaces.com/landing-page/widget/widgetIcon.png'
                  }
                  alt={'widgetIcon'}
                  className={styles.widgetIcon}
                />
                <div
                  className={styles.topnav}
                  style={languageDirection === 'rtl' ? { direction: 'rtl' } : { direction: 'ltr' }}
                >
                  <a href={`/${window.location.pathname.slice(1, 3)}`} className={styles.active}>
                    <I18n t="Home"></I18n>
                  </a>
                  <a href={`/${window.location.pathname.slice(1, 3)}/guide`}>
                    <I18n t="Guide"></I18n>
                  </a>
                  <a href={`/${window.location.pathname.slice(1, 3)}/plans-pricing`}>
                    <I18n t="Plans & Pricing"></I18n>
                  </a>
                </div>
              </div>
              {/* <div className={`${styles.section9} ${styles.section15}`}> */}
              {/* <button className={styles.signupUrlSmall} onClick={redirect}><I18n t="Sign Up"></I18n></button> */}
              {/* </div> */}
              <LanguageMenu
                right={languageDirection === 'rtl' ? true : false}
                setLanguageDirection={setLanguageDirection}
                setLanguage={setLanguage}
              ></LanguageMenu>
            </div>
          ) : (
            <div
              className={`${styles.menuMobile} ${closeTopBar ? styles.closeMenuMobile : ''}`}
              style={languageDirection === 'rtl' ? { direction: 'rtl' } : { direction: 'ltr' }}
            >
              <img
                src={
                  'https://phonedo-media.fra1.digitaloceanspaces.com/landing-page/widget/widgetIcon.png'
                }
                alt={'widgetIcon'}
                className={`${styles.menuSideMobile} ${styles.widgetIcon}`}
              />
              <div className={`${styles.menuSideMobile}`}>
                <img
                  src={
                    'https://phonedo-media.fra1.digitaloceanspaces.com/landing-page/widget/Menu.png'
                  }
                  alt={'MenuIcon'}
                  className={`${styles.MenuIcon} ${
                    languageDirection === 'rtl' ? styles.MenuIconRtl : ''
                  }`}
                  onClick={() => {
                    setMenu(true);
                  }}
                />
              </div>
            </div>
          )}
          <div
            id="section1"
            className={`${
              languageDirection === 'rtl'
                ? styles.section.concat(' ').concat(styles.sectionRtl)
                : styles.section
            } ${styles.section1Media} ${closeTopBar ? styles.closeMenuMobile : ''}`}
          >
            <img
              className={
                languageDirection === 'rtl'
                  ? styles.homeScreen.concat(' ').concat(styles.rtlFirstBackground)
                  : styles.homeScreen
              }
              src={
                'https://phonedo-media.fra1.digitaloceanspaces.com/landing-page/widget/homeScreen.png'
              }
              alt="homeScreen"
            />
            <div className={styles.flex1}>
              <div
                style={languageDirection === 'rtl' ? { marginRight: '7%' } : { marginLeft: '7%' }}
                className={styles.flexSection1}
              >
                <div style={{ position: 'relative' }} className={styles.header}>
                  <I18n t="Full Customer Support - Full Time - a FRACTION of the Price"></I18n>
                </div>
                <div style={{ position: 'relative' }} className={styles.subheader}>
                  <I18n t="You cant be in all places at once - but we can be there for you"></I18n>
                </div>
                <div style={{ position: 'relative' }} className={styles.subheader2}>
                  <I18n t="Get FULL Coverage - Give your customers the FULL experience they deserve"></I18n>
                </div>
                <button
                  onClick={() => {
                    dropNextPage(8);
                  }}
                  style={{ position: 'absolute', color: '#33354e' }}
                  className={
                    languageDirection === 'rtl'
                      ? styles.smallButton
                          .concat(' ')
                          .concat(styles.smallButtonRtl)
                          .concat(' ')
                          .concat(styles.smallButtonM1)
                      : styles.smallButton.concat(' ').concat(styles.smallButtonM1)
                  }
                >
                  <I18n t="Download The App"></I18n>
                </button>
                <img
                  style={{ position: 'relative' }}
                  className={
                    languageDirection === 'rtl'
                      ? styles.homeScreenMobile.concat(' ').concat(styles.rtlFirstBackground)
                      : styles.homeScreenMobile
                  }
                  src={
                    'https://phonedo-media.fra1.digitaloceanspaces.com/landing-page/widget/homeScreenMobile1.png'
                  }
                  alt="homeScreenMobile"
                />
              </div>
            </div>
          </div>
          <div
            id="section2"
            className={`${
              languageDirection === 'rtl'
                ? styles.section.concat(' ').concat(styles.sectionRtl)
                : styles.section
            } ${styles.section2}`}
          >
            <div className={styles.flex1}>
              <div
                style={{ width: '72%', textAlign: 'center' }}
                className={`${styles.flexSection1} ${styles.flexSection1MobileRtl}`}
              >
                <img
                  className={languageDirection === 'rtl' ? styles.dotsRtl : styles.dots}
                  src={
                    'https://phonedo-media.fra1.digitaloceanspaces.com/landing-page/widget/minimal.gif'
                  }
                  alt={minimal}
                />
                <img
                  className={
                    languageDirection === 'rtl' ? styles.section2menRtl : styles.section2men
                  }
                  src={
                    'https://phonedo-media.fra1.digitaloceanspaces.com/landing-page/widget/section2men.png'
                  }
                  alt="section2men"
                />
                <img
                  className={
                    languageDirection === 'rtl' ? styles.section2menMedia : styles.section2menMedia
                  }
                  src={
                    'https://phonedo-media.fra1.digitaloceanspaces.com/landing-page/widget/section2menMedia.png'
                  }
                  alt="section2menMedia"
                />
                <div
                  className={languageDirection === 'rtl' ? styles.talkChatRtl : styles.talkChat}
                  id="talkChat"
                ></div>
              </div>
              <div
                className={
                  languageDirection === 'rtl' ? styles.flexSection1Rtl : styles.flexSection1
                }
              >
                <div
                  style={{ marginTop: '200px', width: '81%' }}
                  className={`${styles.headerDark} ${styles.section2Media}`}
                >
                  <I18n t="Bringing Back the Human Touch to eCommerce"></I18n>
                </div>
                <div style={{ marginTop: '40px', width: '80%' }} className={styles.subheaderDark}>
                  <I18n t="Convenience and selection are king and queen of online retail - yet were missing a major piece of the puzzle - the human touch"></I18n>
                </div>
                <button
                  onClick={() => {
                    dropNextPage(8);
                  }}
                  style={{ marginTop: '50px' }}
                  className={styles.smallButton}
                >
                  <I18n t="Download The App"></I18n>
                </button>
              </div>
            </div>
          </div>
          <div
            id="section3"
            className={
              languageDirection === 'rtl'
                ? styles.section.concat(' ').concat(styles.sectionRtl)
                : styles.section
            }
          >
            <div className={styles.subSection}>
              <div className={`${styles.flexSection1} ${styles.section3Merge}`}>
                <div
                  style={{ marginTop: '10%' }}
                  className={`${styles.headerDark} ${styles.headerSec3}`}
                >
                  <I18n t="FULL Support - The future of Experiential eCommerce"></I18n>
                </div>
                <div
                  style={{
                    marginTop: '60px',
                    marginBottom: '20px',
                    width: '620px',
                  }}
                  className={styles.subheaderDark}
                >
                  <I18n t="FULL is a decentralized virtual sales and support force for eCommerce With one simple widget transform ANY eCommerce store into a personalized shopping experience seamlessly and continuously"></I18n>
                </div>
                <button
                  onClick={() => {
                    dropNextPage(8);
                  }}
                  style={{ marginTop: '100px', marginBottom: '7%' }}
                  className={styles.smallButton}
                >
                  <I18n t="Download The App"></I18n>
                </button>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div
                  id="characters"
                  className={
                    languageDirection === 'rtl' ? styles.animSection3Rtl : styles.animSection3
                  }
                ></div>
              </div>
            </div>
          </div>
          <div id="section4" className={styles.section}>
            <div className={styles.section4Container}>
              <div
                className={`${
                  languageDirection === 'rtl' ? styles.headerDarkRtl : styles.headerDark
                } ${styles.section4Media}`}
              >
                <I18n t="Full Experience - End to End"></I18n>
              </div>
              <div className={styles.flex}>
                {experience.map((item, index) => (
                  <div className={styles.stepCard} key={'4' + item.id}>
                    <img
                      style={{ width: '35%' }}
                      alt="gif"
                      src={require(`../../images/${item.gif}.gif`)}
                    />
                    <div className={styles.headerStepinCard} style={{ textAlign: 'center' }}>
                      <I18n t={item.details}></I18n>
                    </div>
                    <div className={languageDirection === 'rtl' ? styles.stepRtl : styles.step}>
                      <I18n t={item.sentence}></I18n>
                    </div>
                  </div>
                ))}
              </div>
              <button
                onClick={() => {
                  dropNextPage(8);
                }}
                style={{ marginTop: '60px' }}
                className={styles.smallButton}
              >
                <I18n t="Download The App"></I18n>
              </button>
            </div>
          </div>
          <div id="section5" className={`${styles.section} ${styles.section5_1}`}>
            <div
              style={{
                backgroundColor: '#F6F5FB',
                position: 'relative',
                top: '14%',
              }}
              className={`${styles.section5}`}
            >
              <div className={`${styles.headerDark} ${styles.headerDark5}`}>
                <I18n t="We help you to be Fully"></I18n>
              </div>
              <div className={`${styles.gridFully} ${styles.section5body}`}>
                {beFully.map((item, index) => (
                  <div className={styles.containerfully} key={'5' + item.id}>
                    <img
                      className={styles.imgFully}
                      alt={item.imgFully}
                      src={require(`../../images/${item.imgFully}.gif`)}
                    />
                    <div className={styles.fully}>
                      <I18n t="fully"></I18n>
                    </div>
                    <div className={styles.fullyheader}>
                      <I18n t={item.details}></I18n>
                    </div>
                  </div>
                ))}
              </div>
              <button
                onClick={() => {
                  dropNextPage(8);
                }}
                style={{ marginBottom: '40px' }}
                className={styles.smallButton}
              >
                <I18n t="Download The App"></I18n>
              </button>
            </div>
          </div>
          <div
            id="section6"
            className={
              languageDirection === 'rtl'
                ? styles.section
                    .concat(' ')
                    .concat(styles.sectionRtl)
                    .concat(' ')
                    .concat(styles.section6_1)
                : styles.section.concat(' ').concat(styles.section6_1)
            }
          >
            <div className={styles.flex1}>
              <div
                style={
                  languageDirection === 'rtl'
                    ? { marginRight: '10%', marginTop: '120px' }
                    : { marginLeft: '10%', marginTop: '120px' }
                }
                className={`${styles.flexSection1} ${styles.section6Mobile}`}
              >
                <div style={{ placeContent: 'start' }} className={styles.headerDark}>
                  <I18n t="We are Fully on top"></I18n>
                </div>
                <div className={`${styles.subheaderDark} ${styles.space6}`}>
                  <I18n t="During an interaction the screen is shared so you are on the same page Agent has FULL access to the back end control panel to FULLY assist customers"></I18n>
                </div>
                {isMobile === false ? (
                  <></>
                ) : (
                  <button
                    onClick={() => {
                      dropNextPage(8);
                    }}
                    style={{ marginBottom: '40px', marginTop: '-17px' }}
                    className={`${styles.smallButton} ${styles.btnMobile6}`}
                  >
                    <I18n t="Download The App"></I18n>
                  </button>
                )}
                <div className={styles.cons}>
                  {votes.map((item, index) => (
                    <div
                      className={
                        languageDirection === 'rtl'
                          ? styles.grid.concat(' ').concat(styles.gridRtl)
                          : styles.grid
                      }
                      key={'6' + item.id}
                    >
                      <div
                        style={{ background: item.colorBackground }}
                        className={styles.voteContainer}
                      >
                        <div className={styles.gif} id={item.gif}></div>
                      </div>
                      <div className={styles.headerStep}>
                        <I18n t={item.text}></I18n>
                      </div>
                    </div>
                  ))}
                </div>

                <div
                  className={languageDirection === 'rtl' ? styles.talkChatRtl : styles.talkChat}
                  id="thumbupTry"
                ></div>
                {isMobile === false ? (
                  <button
                    onClick={() => {
                      dropNextPage(8);
                    }}
                    style={{ marginBottom: '40px' }}
                    className={styles.smallButton}
                  >
                    <I18n t="Download The App"></I18n>
                  </button>
                ) : (
                  <></>
                )}
              </div>
              <div className={styles.flexSection1}>
                <img
                  className={styles.section6phone}
                  src={
                    'https://phonedo-media.fra1.digitaloceanspaces.com/landing-page/widget/section6phone.png'
                  }
                  alt="section6phone"
                />
              </div>
            </div>
          </div>
          <div
            id="section7"
            className={`${
              languageDirection === 'rtl'
                ? styles.section.concat(' ').concat(styles.sectionRtl)
                : styles.section
            } ${styles.section7}`}
          >
            <div style={{ marginTop: '138px' }} className={`${styles.flex} ${styles.flexSection6}`}>
              <div
                style={{ width: '72%', textAlign: 'center' }}
                className={`${styles.flexSection1} ${styles.section7Media}`}
              >
                <img
                  className={
                    languageDirection === 'rtl'
                      ? styles.section7menRtl.concat(' ').concat(styles.section7men)
                      : styles.section7men
                  }
                  src={
                    'https://phonedo-media.fra1.digitaloceanspaces.com/landing-page/widget/section7men.png'
                  }
                  alt="section7men"
                />
              </div>
              <div
                style={{ width: '86%' }}
                className={
                  languageDirection === 'rtl' ? styles.flexSection1Rtl : styles.flexSection1
                }
              >
                <div
                  style={
                    languageDirection === 'rtl'
                      ? { textAlign: 'start', position: 'relative' }
                      : { textAlign: 'start', position: 'relative' }
                  }
                >
                  <img
                    className={
                      languageDirection === 'rtl' ? styles.section7CircleRtl : styles.section7Circle
                    }
                    src={
                      'https://phonedo-media.fra1.digitaloceanspaces.com/landing-page/widget/section12Circle.png'
                    }
                    alt="section12Circle"
                  />
                  <div className={`${styles.headerDark} ${styles.size6}`}>
                    <I18n t="Full Support Human Widget - The Future of Doing is Here"></I18n>
                  </div>
                  <div className={`${styles.headerDark6}`}>
                    <I18n t="FULL Service - Flat Fee"></I18n>
                  </div>
                  <div className={`${styles.headerDark3}`}>
                    $0.99 <I18n t="per minute of interaction"></I18n>
                  </div>
                  <div className={styles.subheaderDark} style={{ width: '83%', height: 'auto' }}>
                    <I18n t="We charge a low flat rate per minute sticking to your budget with no limits on hours and types of support Even as you sleep rest assured your business is in good hands"></I18n>
                  </div>
                  <div className={styles.CloseCircle}>
                    {section7Map.map((item, index) => (
                      <div className={styles.grid} key={'7' + item.id}>
                        <div>
                          <AiFillCloseCircle size={24} color="#FC9BA1" />
                        </div>
                        <div className={styles.sentence}>
                          <I18n t={item.sentence}></I18n>
                        </div>
                      </div>
                    ))}
                  </div>
                  <button
                    onClick={() => {
                      dropNextPage(8);
                    }}
                    style={{ marginBottom: '40px' }}
                    className={styles.smallButton}
                  >
                    <I18n t="Download The App"></I18n>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            id="section8"
            className={`${
              languageDirection === 'rtl'
                ? styles.section.concat(' ').concat(styles.sectionRtl)
                : styles.section
            } ${styles.section8_1}`}
          >
            <div className={styles.section8}>
              <img
                className={styles.backSection8}
                src={
                  'https://phonedo-media.fra1.digitaloceanspaces.com/landing-page/widget/backSection8.png'
                }
                alt="backSection8"
              />
              <img
                className={styles.backSection8Media}
                src={
                  'https://phonedo-media.fra1.digitaloceanspaces.com/landing-page/widget/backSection8Media.png'
                }
                alt="backSection8Media"
              />
              <div className={styles.section8Elements}>
                <div style={{ width: '100%' }} className={styles.headerDark}>
                  <I18n t="Fully Seamless"></I18n>
                </div>
                {languageDirection === 'rtl' ? (
                  <img
                    className={styles.numbers}
                    src={
                      'https://phonedo-media.fra1.digitaloceanspaces.com/landing-page/widget/numbers-rtl.png'
                    }
                    alt={'numbers'}
                  />
                ) : (
                  <img
                    className={styles.numbers}
                    src={
                      'https://phonedo-media.fra1.digitaloceanspaces.com/landing-page/widget/numbers.png'
                    }
                    alt={'numbers'}
                  />
                )}
                <div className={styles.numbersSection8}>
                  <div style={{ textAlign: 'start' }} className={styles.numbersSentes}>
                    <I18n t="Download The App"></I18n>
                  </div>
                  <div
                    style={{ textAlign: 'center' }}
                    className={`${styles.numbersSentes} ${styles.secondSentes}`}
                  >
                    <I18n t="Enroll"></I18n>
                  </div>
                  <div style={{ textAlign: 'end' }} className={styles.numbersSentes}>
                    <I18n t="Set Service Rules"></I18n>
                  </div>
                </div>
                <div
                  className={`${styles.numbersSentesMedia} ${styles.numbersSentes} ${styles.sentesMedia1}`}
                >
                  <I18n t="Download The App"></I18n>
                </div>
                <div
                  className={`${styles.numbersSentesMedia} ${styles.numbersSentes} ${styles.sentesMedia2}`}
                >
                  <I18n t="Enroll"></I18n>
                </div>
                <div
                  className={`${styles.numbersSentesMedia} ${styles.numbersSentes} ${styles.sentesMedia3}`}
                >
                  <I18n t="Set Service Rules"></I18n>
                </div>
              </div>
            </div>
          </div>
          {isMobile === false ? (
            <div
              id="section9"
              style={{ overflowY: 'hidden' }}
              className={`${styles.section} ${styles.section9_1}`}
            >
              <div style={{ marginTop: '3vh' }} className={styles.flex}>
                <img className={styles.minimal} src={minimal} alt={minimal} />
                <div
                  style={{
                    position: 'relative',
                    left: '220px',
                    bottom: '40px',
                  }}
                  className={`${styles.section9body}`}
                >
                  <div
                    style={
                      languageDirection === 'rtl'
                        ? {
                            position: 'relative',
                            placeContent: 'end',
                            direction: 'rtl',
                            height: 'unset',
                          }
                        : {
                            position: 'relative',
                            placeContent: 'unset',
                            height: 'unset',
                          }
                    }
                    className={`${styles.headerDark} ${styles.title1Sect9}`}
                  >
                    {' '}
                    <I18n t="We are Fully integrated"></I18n>
                  </div>
                  <div
                    style={{
                      position: 'relative',
                      placeContent: 'unset',
                      lineHeight: 'unset',
                      height: '50px',
                    }}
                    className={`${styles.subheaderDark} ${styles.title2Sect9}`}
                  >
                    {' '}
                    <I18n t="Your site does not appear on any of these platforms? no worries!"></I18n>
                  </div>
                  <div
                    style={{
                      position: 'relative',
                      placeContent: 'unset',
                      lineHeight: 'unset',
                      height: '50px',
                      cursor: 'pointer',
                      textDecoration: 'underline',
                    }}
                    className={`${styles.subheaderDark} ${styles.title3Sect9}`}
                    onClick={() => {
                      window.location.replace('https://full.bpo.do');
                    }}
                  >
                    {' '}
                    <I18n t="Click here to join the service directly"></I18n>
                  </div>
                  <div
                    style={{
                      fontSize: '32px',
                      position: 'relative',
                      placeContent: 'unset',
                      lineHeight: 'unset',
                    }}
                    className={`${styles.headerDark} ${styles.textSect9}`}
                  >
                    {' '}
                    <I18n t="Download the App here"></I18n>
                  </div>
                  <img
                    className={styles.leftArrowCurved}
                    src={
                      'https://phonedo-media.fra1.digitaloceanspaces.com/landing-page/widget/LeftArrowCurved.png'
                    }
                    alt={'LeftArrowCurved'}
                  />
                </div>
                <div className={styles.integratedgrid1}>
                  <div className={styles.integratedgrid1}>
                    <div style={{ boxShadow: 'none' }} className={styles.integrated}></div>
                  </div>
                  <div className={`${styles.integrated} ${styles.wooLogo}`}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                      }}
                    >
                      <div style={{ height: '50%' }} className={styles.integratedImgDiv}>
                        <img
                          style={{ marginTop: '58px' }}
                          className={styles.wooImg}
                          src={
                            'https://phonedo-media.fra1.digitaloceanspaces.com/landing-page/widget/wooLogo.png'
                          }
                          alt={'wooLogo'}
                        />
                      </div>
                      <div
                        style={{ marginTop: '10%', height: '50%' }}
                        className={styles.integratedLable}
                      >
                        <div>WOO COMMERCE</div>{' '}
                      </div>{' '}
                    </div>
                  </div>
                  <div className={styles.integratedgrid1}>
                    <div style={{ boxShadow: 'none' }} className={styles.integrated}></div>
                  </div>
                  <div className={styles.integratedgrid1}>
                    <div style={{ boxShadow: 'none' }} className={styles.integrated}></div>
                  </div>
                  <div className={styles.integratedgrid1}>
                    <div style={{ boxShadow: 'none' }} className={styles.integrated}></div>
                  </div>
                  <div className={`${styles.integrated} ${styles.spaceI}`}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                      }}
                    >
                      <div style={{ height: '50%' }} className={styles.integratedImgDiv}>
                        <img
                          style={{ marginTop: '17px' }}
                          className={styles.integratedImg1}
                          src={
                            'https://phonedo-media.fra1.digitaloceanspaces.com/landing-page/widget/magentoLogo.png'
                          }
                          alt={'magentLogo'}
                        />
                      </div>
                      <div
                        style={{ marginTop: '10%', height: '50%' }}
                        className={styles.integratedLable}
                      >
                        <div>Magento Commerce</div>
                      </div>{' '}
                    </div>
                  </div>
                  <div className={styles.integratedgrid}>
                    <div className={`${styles.integrated} ${styles.wooLogo}`}>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          height: '100%',
                        }}
                      >
                        <div style={{ height: '50%' }} className={styles.integratedImgDiv}>
                          <img
                            style={{ marginTop: '58px' }}
                            className={styles.integratedImg2}
                            src={
                              'https://phonedo-media.fra1.digitaloceanspaces.com/landing-page/widget/bigLogo.png'
                            }
                            alt={'bigLogo'}
                          />
                        </div>
                        <div
                          style={{ marginTop: '10%', height: '50%' }}
                          className={styles.integratedLable}
                        >
                          <div>BIGCOMMERCE</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style={{ zIndex: '1' }} className={styles.integratedgrid}>
                    <div
                      className={`${styles.integrated} ${styles.spaceI}`}
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        window.open('https://apps.shopify.com/support-widget ');
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          height: '100%',
                        }}
                      >
                        <div style={{ height: '50%' }}>
                          <img
                            style={{ marginTop: '8%' }}
                            className={styles.integratedImg1}
                            src={
                              'https://phonedo-media.fra1.digitaloceanspaces.com/landing-page/widget/shopifyLogo.png'
                            }
                            alt={'shopifyLogo'}
                          />
                        </div>
                        <div
                          style={{ marginTop: '10%', height: '50%' }}
                          className={styles.integratedLable}
                        >
                          <div>Shopify</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ zIndex: '1' }} className={styles.integratedgrid}>
                  {integrated.map((item, index) => (
                    <div
                      className={styles.integrated}
                      key={'9' + item.id}
                      style={item.navigateUrl !== '' ? { cursor: 'pointer' } : {}}
                      onClick={() => {
                        item.navigateUrl !== '' && window.open(item.navigateUrl);
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          height: '100%',
                        }}
                      >
                        <div style={{ height: '50%' }} className={styles.integratedImgDiv}>
                          <img
                            style={{ marginTop: '8%' }}
                            className={styles.integratedImg1}
                            src={require(`../../images/${item.integratedImg}.png`)}
                            alt={item.integratedName}
                          />
                        </div>
                        <div
                          style={{ marginTop: '10%', height: '50%' }}
                          className={styles.integratedLable}
                        >
                          <div>{item.integratedName}</div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ) : null}
          <div id="section10" className={styles.section}>
            <div className={styles.flexSection10}>
              <div
                style={{ position: 'relative', top: 'unset', height: 'unset' }}
                className={`${styles.headerDark} ${styles.headerVideo} ${
                  languageDirection === 'rtl' ? styles.sectionRtlMobile : ''
                }`}
              >
                <I18n t="Watch our video"></I18n>
              </div>
              <div
                className={`${styles.text10} ${
                  languageDirection === 'rtl' ? styles.sectionRtlMobile : ''
                }`}
              >
                <I18n t="It is best for you to rotate the screen and watch the video in full screen"></I18n>
              </div>
              <div className={styles.videoSection10}>
                <ReactPlayer
                  id="video1"
                  className={styles.ourVideo}
                  controls={true}
                  muted={true}
                  playing={videoPlay}
                  playsinline={true}
                  onMouseEnter={() => {
                    setVideoPlay(true);
                  }}
                  onMouseLeave={() => {
                    setVideoPlay(false);
                  }}
                  url={'https://www.youtube.com/watch?v=VBYDo9uVej0'}
                />
              </div>
            </div>
            {isMobile === false ? (
              <img
                style={{ width: '100%' }}
                src={
                  'https://phonedo-media.fra1.digitaloceanspaces.com/landing-page/widget/section10.png'
                }
                alt={'section10'}
              />
            ) : (
              <></>
            )}
          </div>

          <div id="section11" className={`${styles.section} ${styles.mobile11}`}>
            <SliderSection swiperSlide={styles.swiperSlide} />
          </div>
          {isMobile === false ? (
            <div
              id="section12"
              className={styles.section12}
              style={languageDirection === 'rtl' ? { direction: 'rtl' } : { direction: 'ltr' }}
            >
              <div style={{ transform: 'scaleX(-1)' }}>
                <img
                  alt="icon"
                  style={languageDirection === 'rtl' ? { transform: 'scaleX(1)' } : {}}
                  src={
                    'https://phonedo-media.fra1.digitaloceanspaces.com/landing-page/widget/LineSection12_1.png'
                  }
                  className={styles.imgSection12_1}
                />
              </div>
              <div className={styles.section12Body}>
                <img
                  alt="background"
                  src={
                    'https://phonedo-media.fra1.digitaloceanspaces.com/landing-page/widget/dotsSectio12.png'
                  }
                  className={
                    languageDirection === 'rtl' ? styles.dotsSectio12Rtl : styles.dotsSectio12
                  }
                />
                <div className={styles.header12}>
                  <I18n t="Our multilingual professional staff is at your disposal by domains"></I18n>
                </div>
                <img
                  alt="icon"
                  src={
                    'https://phonedo-media.fra1.digitaloceanspaces.com/landing-page/widget/LineSection12_2.png'
                  }
                  className={styles.imgSection12_2}
                  style={languageDirection === 'rtl' ? { transform: 'scaleX(-1)' } : {}}
                />
                {section12List.map((rowItem, index) => (
                  <div className={styles.rowItem} key={'12row' + rowItem.row}>
                    {rowItem.data.map((item, index) => (
                      <div
                        style={item.id === 0 ? { background: 'transparent' } : {}}
                        className={styles.colItem}
                        key={'12' + item.id}
                      >
                        <div style={item.id === 0 ? { display: 'none' } : {}}>
                          <img alt="icon" src={item.img} className={styles.colImg}></img>
                        </div>
                        <div
                          style={item.id === 0 ? { display: 'none' } : {}}
                          className={styles.colTitle}
                        >
                          <span>
                            <I18n t={item.name}></I18n>
                          </span>
                        </div>
                        <div
                          style={item.id === 0 ? { display: 'none' } : {}}
                          className={styles.colText}
                        >
                          <span>
                            {item.numAgent} <I18n t="Agents"></I18n>
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div
              id="section12"
              className={`${styles.section12Mobile}`}
              style={languageDirection === 'rtl' ? { direction: 'rtl' } : { direction: 'ltr' }}
            >
              <div style={{ transform: 'scaleX(-1)' }}>
                <img
                  alt="LineSection12Mobile_1"
                  style={languageDirection === 'rtl' ? { transform: 'scaleX(1)' } : {}}
                  src={
                    'https://phonedo-media.fra1.digitaloceanspaces.com/landing-page/widget/LineSection12Mobile_1.png'
                  }
                  className={styles.imgSection12_1}
                />
              </div>
              <div className={` ${styles.section12MobileBody}`}>
                <div className={styles.header12}>
                  <I18n t="Our multilingual professional staff is at your disposal by domains"></I18n>
                </div>
                <img
                  alt="LineSection12Mobile_2"
                  src={
                    'https://phonedo-media.fra1.digitaloceanspaces.com/landing-page/widget/LineSection12Mobile_2.png'
                  }
                  className={styles.imgSection12_2}
                  style={languageDirection === 'rtl' ? { transform: 'scaleX(-1)' } : {}}
                />
                {allDomains
                  ? section12ListMobile.map((rowItem, index) => (
                      <div className={styles.rowItem} key={'12row' + rowItem.row}>
                        {rowItem.data.map((item1, i) => (
                          <div
                            style={item1.id === 39 ? { background: 'transparent' } : {}}
                            className={styles.colItem}
                            key={'12' + item1.id}
                          >
                            <div style={item1.id === 39 ? { display: 'none' } : {}}>
                              <img alt="icon" src={item1.img}></img>
                            </div>
                            <div
                              style={item1.id === 39 ? { display: 'none' } : {}}
                              className={styles.colTitle}
                            >
                              <span>
                                <I18n t={item1.name}></I18n>
                              </span>
                            </div>
                            <div
                              style={item1.id === 39 ? { display: 'none' } : {}}
                              className={styles.colText}
                            >
                              <span>
                                {item1.numAgent} <I18n t="Agents"></I18n>
                              </span>
                            </div>
                          </div>
                        ))}
                      </div>
                    ))
                  : section12ListMobile.slice(0, 4).map((rowItem, index) => (
                      <div className={styles.rowItem} key={'12row' + rowItem.row}>
                        {rowItem.data.map((item1, i) => (
                          <div
                            style={item1.id === 39 ? { background: 'transparent' } : {}}
                            className={styles.colItem}
                            key={'12' + item1.id}
                          >
                            <div style={item1.id === 39 ? { display: 'none' } : {}}>
                              <img alt="icon" src={item1.img}></img>
                            </div>
                            <div
                              style={item1.id === 39 ? { display: 'none' } : {}}
                              className={styles.colTitle}
                            >
                              <span>
                                <I18n t={item1.name}></I18n>
                              </span>
                            </div>
                            <div
                              style={item1.id === 39 ? { display: 'none' } : {}}
                              className={styles.colText}
                            >
                              <span>
                                {item1.numAgent} <I18n t="Agents"></I18n>
                              </span>
                            </div>
                          </div>
                        ))}
                      </div>
                    ))}
                <div
                  className={`${styles.section12Btn}`}
                  onClick={() => {
                    setAllDomains(!allDomains);
                  }}
                >
                  {allDomains ? (
                    <span>
                      <I18n t="Close all domains"></I18n>
                    </span>
                  ) : (
                    <span>
                      <I18n t="See all domains"></I18n>
                    </span>
                  )}
                </div>
              </div>
            </div>
          )}
          <div id="section13" className={`${styles.section13}`}>
            <div
              className={`${
                languageDirection === 'rtl'
                  ? styles.section.concat(' ').concat(styles.sectionRtl)
                  : styles.section
              } ${styles.section13_1}`}
              style={{ backgroundColor: '#F9F9F9' }}
            >
              <div
                style={{ width: '70%', marginBottom: '70px' }}
                className={`${styles.flex} ${styles.section13body}`}
              >
                <div>
                  <div
                    style={
                      languageDirection === 'rtl'
                        ? { position: 'relative', top: '100px' }
                        : { position: 'relative', top: '100px' }
                    }
                    className={styles.headerDark}
                  >
                    <I18n t="Our Customers Are Fully Satisfied"></I18n>
                  </div>
                  <img
                    className={
                      languageDirection === 'rtl'
                        ? styles.section12CircleRtl
                        : styles.section12Circle
                    }
                    src={
                      'https://phonedo-media.fra1.digitaloceanspaces.com/landing-page/widget/section12Circle.png'
                    }
                    alt="section12Circle"
                  />
                </div>
                <div>
                  <img
                    className={languageDirection === 'rtl' ? styles.lineRtl : styles.line}
                    src={
                      'https://phonedo-media.fra1.digitaloceanspaces.com/landing-page/widget/line.png'
                    }
                    alt="line"
                  />
                </div>
              </div>
              {isMobile === false ? (
                <div className={styles.flexCard}>
                  {card.map((item, index) => (
                    <div className={styles.card} key={'13' + item.id}>
                      <div className={styles.gridCard}>
                        <img
                          className={styles.imgCard}
                          src={require(`../../images/${item.img}.png`)}
                          alt={item.img}
                        />
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            textAlign: 'justify',
                          }}
                        >
                          <div className={styles.nameCard}>{item.title}</div>
                          <div className={styles.positionCard}>
                            <I18n t={item.subTitle}></I18n>
                          </div>
                        </div>
                      </div>
                      <div style={{ textAlign: 'start' }} className={styles.feedback}>
                        <I18n t={item.feedback}></I18n>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div>
                  <Slider {...settings}>
                    {card.map((item, index) => (
                      <div
                        className={`${idxCard !== index ? styles.card : styles.cardCenter}`}
                        key={'13' + item.id}
                      >
                        <div className={styles.gridCard}>
                          <img
                            className={styles.imgCard}
                            src={require(`../../images/${item.img}.png`)}
                            alt={item.img}
                          />
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              textAlign: 'justify',
                            }}
                          >
                            <div className={styles.nameCard}>{item.title}</div>
                            <div className={styles.positionCard}>
                              <I18n t={item.subTitle}></I18n>
                            </div>
                          </div>
                        </div>
                        <div style={{ textAlign: 'start' }} className={styles.feedback}>
                          <I18n t={item.feedback}></I18n>
                        </div>
                      </div>
                    ))}
                  </Slider>
                </div>
              )}
              <img
                className={styles.ellipse}
                src={
                  'https://phonedo-media.fra1.digitaloceanspaces.com/landing-page/widget/ellipse.png'
                }
                alt="ellipse"
              />
            </div>
          </div>
          <div
            id="section14"
            className={`${
              languageDirection === 'rtl'
                ? styles.section.concat(' ').concat(styles.sectionRtl)
                : styles.section
            } ${styles.section14}`}
            style={{ height: 'auto' }}
          >
            <div className={styles.flex1}>
              <div
                style={languageDirection === 'rtl' ? { marginRight: '10%' } : { marginLeft: '10%' }}
                className={styles.flexSection1}
              >
                <div
                  style={{ height: 'unset', marginTop: '100px' }}
                  className={`${styles.headerDark} ${styles.headerDark14}`}
                >
                  <I18n t="Enough Looking Get Started with Our unique Widget application"></I18n>
                </div>
                <br />
                <br />
                <br />

                {/* <div className={`${styles.subheaderDark2}`}><I18n t="Download our App"></I18n></div> */}
                <button
                  style={{ marginBottom: '40px' }}
                  className={styles.smallButton}
                  onClick={() => {
                    dropNextPage(8);
                  }}
                >
                  <I18n t="Download The App"></I18n>
                </button>
              </div>
              <div
                style={{ marginLeft: '10%' }}
                className={
                  languageDirection === 'rtl' ? styles.flexSection1Rtl : styles.flexSection1
                }
              >
                <img
                  className={styles.iphoneSection13}
                  src={
                    'https://phonedo-media.fra1.digitaloceanspaces.com/landing-page/widget/section14.png'
                  }
                  alt="section14"
                />
                <img
                  className={styles.iphoneSection13Media}
                  src={
                    'https://phonedo-media.fra1.digitaloceanspaces.com/landing-page/widget/iphoneMedia.png'
                  }
                  alt="iphoneMedia"
                />
              </div>
            </div>
          </div>

          <div
            id="section15"
            className={`${
              languageDirection === 'rtl'
                ? styles.section.concat(' ').concat(styles.sectionRtl)
                : styles.section
            }  ${closeTopBar ? styles.closeMenuMobile : ''}`}
          >
            <img
              className={
                languageDirection === 'rtl'
                  ? styles.homeScreen.concat(' ').concat(styles.rtlFirstBackground)
                  : styles.homeScreen
              }
              src={
                'https://phonedo-media.fra1.digitaloceanspaces.com/landing-page/widget/section15%20%281%29.png'
              }
              alt={'Section15'}
            />
            <img
              className={
                languageDirection === 'rtl'
                  ? styles.homeScreenMobile15.concat(' ').concat(styles.rtlFirstBackground)
                  : styles.homeScreenMobile15
              }
              src={
                'https://phonedo-media.fra1.digitaloceanspaces.com/landing-page/widget/contactUsMobile.png'
              }
              alt={'contactUsMobile'}
            />

            <div className={styles.flex1}>
              <div
                style={languageDirection === 'rtl' ? { marginRight: '7%' } : { marginLeft: '7%' }}
                className={styles.flexSection1}
              >
                <div className={styles.contectForm}>
                  <Form<ConectUsForm>
                    initialValues={landingPageStore.conectUsForm}
                    onSubmit={async (form: any) => {
                      if (form.formButton === 'SendTheMassage') {
                        const response = await landingPageStore.submitConactUs(form);
                        if (response) {
                          if (response.status >= 200 && response.status < 300) {
                            return console.log('the mail sended');
                          }
                          return response;
                        }
                      }
                    }}
                  >
                    {({ submitError, handleSubmit, form, values }) => (
                      <form onSubmit={handleSubmit} className={styles.registrationForm}>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                          <div style={{ alignSelf: 'center', marginTop: '5%' }}>
                            <PageTitle
                              // className={styles.header15}
                              style={{ justifyContent: 'flex-start' }}
                              title={I18n.getTranslation(location, 'Contact us')}
                            />
                            <SubTitle
                              // className={styles.subheader15}
                              style={{
                                justifyContent: 'flex-start',
                                padding: 'unset',
                                marginBottom: '4%',
                              }}
                              title={I18n.getTranslation(
                                location,
                                'Want to share something with us?'
                              )}
                            />
                          </div>
                          <div className={styles.fieldFlexC}>
                            <Field
                              name={'type'}
                              options={ReasonForRequest}
                              placeholder={I18n.getTranslation(location, 'Reason for request')}
                              component={InputSelect}
                            />
                            <div className={styles.fieldFlex1}>
                              <Field
                                name={'fullName'}
                                inputType={'text'}
                                placeholder={I18n.getTranslation(location, 'Full name')}
                                component={InputField}
                                inputClass={styles.bigInputText2}
                              />
                              <Field
                                name={'companyName'}
                                inputType={'text'}
                                placeholder={I18n.getTranslation(location, 'Company name')}
                                component={InputField}
                                inputClass={styles.bigInputText2}
                              />
                            </div>
                            <Field
                              name={'emailAddress'}
                              inputType={'text'}
                              placeholder={I18n.getTranslation(location, 'Email address')}
                              component={InputField}
                              inputClass={styles.bigInputText}
                            />
                            <div style={{ display: 'flex' }} className={styles.fieldFlex2}>
                              <Field
                                style={{
                                  marginInlineStart: 'unset',
                                  webkitMarginStart: 'unset',
                                }}
                                name={'countryCode'}
                                options={countryCodeList}
                                placeholder={I18n.getTranslation(location, 'Code')}
                                component={InputSelectCountry}
                              />
                              <Field
                                style={{ width: '70%' }}
                                name={'telephone'}
                                inputType={'text'}
                                placeholder={I18n.getTranslation(location, 'Telephone')}
                                component={InputField}
                                inputClass={styles.field}
                              />
                            </div>
                            <Field
                              name={'description'}
                              inputType={'text'}
                              placeholder={I18n.getTranslation(location, 'Subject')}
                              inputClass={styles.bigInputText1}
                              component={InputTextArea}
                            />
                            {submitError && (
                              <div className={styles.submitError}>
                                <I18n t={submitError} />
                              </div>
                            )}
                          </div>
                          {/* <button onClick={() => { values.telephone && values.countryCode ? form.change('formButton', 'SendTheMassage') : form.change('formButton', null) }} style={{ 'position': 'relative', 'color': '#33354e' }} className={languageDirection === "rtl" ? styles.smallButton15.concat(" ").concat(styles.smallButtonRtl).concat(" ").concat(styles.smallButtonM1) : styles.smallButton15.concat(" ").concat(styles.smallButtonM1)}><I18n t="Send the message" /> </button> */}
                          <div style={{ width: '100%', textAlign: 'center' }}>
                            <button
                              style={{ position: 'relative', color: '#33354e' }}
                              onClick={() => {
                                values.type &&
                                values.fullName &&
                                values.telephone &&
                                values.emailAddress
                                  ? form.change('formButton', 'SendTheMassage')
                                  : form.change('formButton', null);
                              }}
                              id={
                                values.type &&
                                values.fullName &&
                                values.telephone &&
                                values.emailAddress
                                  ? 'buttonCreateApprove'
                                  : 'buttonCreateDisable'
                              }
                              className={
                                languageDirection === 'rtl'
                                  ? styles.smallButton15
                                      .concat(' ')
                                      .concat(styles.smallButton15)
                                      .concat(' ')
                                      .concat(styles.smallButtonM1)
                                  : styles.smallButton15.concat(' ').concat(styles.smallButtonM1)
                              }
                            >
                              <I18n t="Send the message" />{' '}
                            </button>
                            {/* disabled={flag} */}
                          </div>
                        </div>
                      </form>
                    )}
                  </Form>
                  {/* <div className={styles.header15}>Contect us</div>
                  <div className={styles.subheader15}>Want to share something with us?</div> */}
                </div>
                {/* <img style={{ 'position': 'relative' }} className={languageDirection === "rtl" ? styles.homeScreenMobile.concat(" ").concat(styles.rtlFirstBackground) : styles.homeScreenMobile} src={"https://phonedo-media.fra1.digitaloceanspaces.com/landing-page/widget/section15%20%281%29.png"} alt={'Section15'} /> */}
              </div>
            </div>
          </div>
          <div
            id="section16"
            className={`${styles.section} ${styles.borderSction16} ${styles.section16}`}
            style={languageDirection === 'rtl' ? { direction: 'rtl' } : { direction: 'ltr' }}
          >
            <div className={`${styles.part}`}>
              <img
                src={
                  'https://phonedo-media.fra1.digitaloceanspaces.com/landing-page/widget/widgetIcon.png'
                }
                alt={'widget16'}
                className={`${styles.widgetIcon16}`}
              />
            </div>
            <div className={`${styles.part} ${styles.middlePart}`}>
              <div>
                @<I18n t="copyright 2022" />, <I18n t="All rights reserved" />
              </div>
              <div className={`${styles.partRow}`}>
                <span
                  className={`${styles.partCol} ${styles.partColSpace}`}
                  onClick={() => {
                    history.push(`/${window.location.pathname.slice(1, 3)}/privacy-policy`);
                  }}
                >
                  <I18n t="Privacy Policy" />
                </span>
                <span
                  className={`${styles.partCol}`}
                  onClick={() => {
                    history.push(`/${window.location.pathname.slice(1, 3)}/terms-condition`);
                  }}
                >
                  <I18n t="Terms & Conditions" />
                </span>
              </div>
            </div>
            <div className={`${styles.partimages}`}>
              <img
                className={styles.contactIcon}
                src={
                  'https://phonedo-media.fra1.digitaloceanspaces.com/landing-page/widget/Facebook.png'
                }
                alt={'facebook'}
                onClick={() => {
                  window.open('https://www.facebook.com/Fulldo-103351595734254');
                }}
              />
              <img
                className={styles.contactIcon}
                src={
                  'https://phonedo-media.fra1.digitaloceanspaces.com/landing-page/widget/instagram.png'
                }
                alt={'instagram'}
                onClick={() => {
                  window.open('https://www.instagram.com/fullwidget');
                }}
              />
              <img
                className={styles.contactIcon}
                src={
                  'https://phonedo-media.fra1.digitaloceanspaces.com/landing-page/widget/twitter.png'
                }
                alt={'twitter'}
                onClick={() => {
                  window.open('https://twitter.com/FullWidget');
                }}
              />
              <img
                className={styles.contactIcon}
                src={
                  'https://phonedo-media.fra1.digitaloceanspaces.com/landing-page/widget/youtube.png'
                }
                alt={'youtube'}
                onClick={() => {
                  window.open('https://www.youtube.com/channel/UC_Q6WW9XCMZtxv69TFzdeYg');
                }}
              />
              <img
                className={styles.contactIcon}
                src={
                  'https://phonedo-media.fra1.digitaloceanspaces.com/landing-page/widget/tiktok.svg'
                }
                alt={'tiktok'}
                onClick={() => {
                  window.open('https://www.tiktok.com/@fullwidget');
                }}
              />
              <img
                className={styles.contactIcon}
                src={
                  'https://phonedo-media.fra1.digitaloceanspaces.com/landing-page/widget/linkedin.svg'
                }
                alt={'linkedin'}
                onClick={() => {
                  window.open('https://www.linkedin.com/company/full-do');
                }}
              />
            </div>
          </div>

          {isMobile === false ? (
            <>
              {
                finalSection ? (
                  <div className={styles.AiOutlineArrowDown}>
                    <AiOutlineArrowUp
                      size={35}
                      color="#42C6A5"
                      onClick={() => {
                        dropNextPage(0);
                      }}
                    />
                  </div>
                ) : (
                  <div className={styles.AiOutlineArrowDown}>
                    <AiOutlineArrowDown
                      size={35}
                      color="#42C6A5"
                      onClick={() => {
                        dropNextPage();
                      }}
                    />
                  </div>
                )
                // <img className={styles.imgDownArrowFixed} src={downArrowIcon} alt={downArrowIcon} onClick={() => { dropNextPage() }} />
              }
            </>
          ) : null}
          <div id="bottombar" className={styles.bottombar}>
            <div className={styles.flex}>
              <button
                onClick={() => {
                  dropNextPage(8);
                }}
                style={{ position: 'relative' }}
                className={styles.button}
              >
                <I18n t="Download The App"></I18n>
              </button>
              <span
                style={
                  languageDirection === 'rtl'
                    ? { direction: 'rtl', position: 'relative' }
                    : { position: 'relative' }
                }
                className={styles.bottombarTitle}
              >
                <I18n t="With our Widget on your site your visitors can talk or chat with our Live Reps 247"></I18n>
              </span>
              <div style={{ display: 'flex', marginInlineEnd: '50px' }}>
                <span style={{ position: 'relative' }} className={styles.subHeaderTopBar}>
                  <I18n t="Try It Now"></I18n>
                </span>
                <div
                  className={`${styles.bottomArrow} ${
                    languageDirection === 'rtl' ? styles.bottomArrowRtl : ''
                  } `}
                >
                  <img
                    style={{ position: 'relative' }}
                    alt={'arrowNextWhite'}
                    src={
                      'https://phonedo-media.fra1.digitaloceanspaces.com/landing-page/widget/arrow_next_white2.svg'
                    }
                  ></img>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
