// import React from 'react';
// import styles from './sub-title.module.scss';

// type Props = {
//   title: React.ReactNode;
//   right?: () => React.ReactNode;
//   padding?: string;
//   margin?: string;
// };

// export const SubTitle = (props: Props) => {
//   const { title, right } = props;

//   return (
//     <div>
//       <h4 className={styles.subTitle}>{title}</h4>
//       {right && right()}
//     </div>
//   );
// };

import React from 'react';
import styles from './sub-title.module.scss';
import cn from 'classnames';

type Props = {
  title: React.ReactNode;
  right?: () => React.ReactNode;
  padding?: string;
} & React.HTMLProps<HTMLDivElement>;

export const SubTitle = (props: Props) => {
  const { title, style, className, ...titleProps /*= '0 0 3.25rem 0', padding = '0',right */ } =
    props;

  return (
    <div className={styles.card}>
      <h4 className={cn(styles.subTitle, className)} style={style} {...titleProps}>
        {title}
      </h4>
    </div>
  );
};
