import styles from './plans-pricing.module.scss';
import style from '../landingPage/landing-page.module.scss';
import 'react-slideshow-image/dist/styles.css';
import React, { useState, useEffect } from 'react';
import I18n from '../../lib/lingui/i18n';
import { LanguageMenu } from '../../lib/final-form/languageMenu.tsx';
import { Divider } from '@react-md/divider';
import { useHistory, useLocation } from 'react-router-dom';
// import { TopMenu } from '/home/leah/phonedo/full-website/src/lib/final-form/top-menu.tsx';
import { TopMenu } from '../../lib/final-form/top-menu.tsx';
import { Field, Form } from 'react-final-form';
import { ConectUsForm } from '../../stores/landing-page-store';
import { useStore } from '../../stores/use-store.tsx';

import { PageTitle } from '../ui/page-title/page-title.tsx';
import { SubTitle } from '../ui/sub-title/sub-title.tsx';
import { InputField } from '../ui/input-field.tsx';
import { InputSelectCountry } from '../ui/input-selectCountry.tsx';
import { InputSelect } from '../ui/input-select.tsx';
import { InputTextArea } from '../ui/input-textarea.tsx';
import { countryCodeList } from '../globals/professional-information.tsx';
import { ReasonForRequest } from '../globals/professional-information.tsx';
import { isMobile } from 'react-device-detect';

export const PlansPricing = (props: any) => {
  const [closeTopBar, setCloseTopBar] = useState(false);
  // const [deviceType, setdeviceType] = useState<string>();
  const [languageDirection, setLanguageDirection] = useState<string>();
  const [menu, setMenu] = useState<boolean>(false);
  const { landingPageStore } = useStore();
  const history = useHistory();

  let { location } = props;
  location = useLocation();

  useEffect(() => {
    // let typeOfDevice = navigator.userAgent.toLocaleLowerCase();
    // if (/window/i.test(typeOfDevice)) {
    //   setDeviceType('desktop')
    // }
    // else if (/android/i.test(typeOfDevice)) {
    //   setDeviceType('android')
    // }
    // else {
    //   setDeviceType('ios');

    // }
    if (window.location.pathname.includes('he') || window.location.pathname.includes('ar')) {
      setLanguageDirection('rtl');
    } else {
      setLanguageDirection('ltr');
    }
  }, []);

  const closeMenu = () => {
    if (window.location.pathname.includes('he') || window.location.pathname.includes('ar')) {
      console.log('hehehehehehe');
      setLanguageDirection('rtl');
    } else {
      console.log('enenenenenen');

      setLanguageDirection('ltr');
    }
    setMenu(false);
  };

  return (
    <div>
      {menu ? (
        <TopMenu close={closeMenu} />
      ) : (
        <div>
          {!closeTopBar && (
            <div
              id="topbar"
              className={
                languageDirection === 'rtl'
                  ? styles.topbar.concat(' ').concat(styles.topbarRtl)
                  : styles.topbar
              }
            >
              {isMobile === false ? (
                <>
                  {' '}
                  <div className={styles.topbarSide}>
                    <img
                      className={
                        languageDirection === 'rtl'
                          ? styles.iconTopBar.concat(' ').concat(styles.iconTopBarRtl)
                          : styles.iconTopBar
                      }
                      src={
                        'https://phonedo-media.fra1.digitaloceanspaces.com/landing-page/widget/arrow-up.png'
                      }
                      alt="arrowUp"
                    />
                    <span className={styles.headerTopBar}>
                      <I18n t="Sales"></I18n> {languageDirection === 'rtl' ? '500%+' : '+500%'}
                    </span>
                    <img
                      className={
                        languageDirection === 'rtl'
                          ? styles.iconTopBar.concat(' ').concat(styles.iconTopBarRtl)
                          : styles.iconTopBar
                      }
                      src={
                        'https://phonedo-media.fra1.digitaloceanspaces.com/landing-page/widget/arrow-down.png'
                      }
                      alt="arrowDown"
                    />
                    <span className={styles.headerTopBar}>
                      <I18n t="Returns"></I18n> {languageDirection === 'rtl' ? '95%-' : '-95%'}{' '}
                    </span>
                    <img
                      className={
                        languageDirection === 'rtl'
                          ? styles.iconTopBar.concat(' ').concat(styles.iconTopBarRtl)
                          : styles.iconTopBar
                      }
                      src={
                        'https://phonedo-media.fra1.digitaloceanspaces.com/landing-page/widget/arrow-up.png'
                      }
                      alt="arrowUp"
                    />
                    <span className={styles.headerTopBar}>
                      <I18n t="Margins"></I18n> {languageDirection === 'rtl' ? '35%+' : '+35%'}
                    </span>
                    <img
                      className={
                        languageDirection === 'rtl'
                          ? styles.iconTopBar.concat(' ').concat(styles.iconTopBarRtl)
                          : styles.iconTopBar
                      }
                      src={
                        'https://phonedo-media.fra1.digitaloceanspaces.com/landing-page/widget/star.png'
                      }
                      alt="star"
                    />
                    <span className={styles.headerTopBar}>
                      <I18n t="Reviews"></I18n> 5
                    </span>
                    <img
                      className={
                        languageDirection === 'rtl'
                          ? styles.iconTopBar.concat(' ').concat(styles.iconTopBarRtl)
                          : styles.iconTopBar
                      }
                      src={
                        'https://phonedo-media.fra1.digitaloceanspaces.com/landing-page/widget/chargebacks.png'
                      }
                      alt="star"
                    />
                    <span className={styles.headerTopBar}>
                      <I18n t="Chargebacks"></I18n> 0%
                    </span>
                  </div>
                  <div className={styles.topbarSide}>
                    <img
                      className={
                        languageDirection === 'rtl' ? styles.closeIconRtl : styles.closeIcon
                      }
                      src={
                        'https://phonedo-media.fra1.digitaloceanspaces.com/landing-page/widget/close.png'
                      }
                      alt="close"
                      onClick={() => {
                        setCloseTopBar(true);
                      }}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className={styles.topbarSide} style={{ paddingInlineStart: '28px' }}>
                    <div className={styles.row}>
                      <img
                        className={
                          languageDirection === 'rtl'
                            ? styles.iconTopBar.concat(' ').concat(styles.iconTopBarRtl)
                            : styles.iconTopBar
                        }
                        src={
                          'https://phonedo-media.fra1.digitaloceanspaces.com/landing-page/widget/arrow-down.png'
                        }
                        alt="arrowDown"
                      />
                      <span className={styles.headerTopBar}>
                        <I18n t="Returns"></I18n> %95-
                      </span>
                    </div>
                    <div className={styles.row}>
                      <img
                        className={
                          languageDirection === 'rtl'
                            ? styles.iconTopBar.concat(' ').concat(styles.iconTopBarRtl)
                            : styles.iconTopBar
                        }
                        src={
                          'https://phonedo-media.fra1.digitaloceanspaces.com/landing-page/widget/arrow-up.png'
                        }
                        alt="arrowUp"
                      />
                      <span className={styles.headerTopBar}>
                        <I18n t="Sales"></I18n> %500+
                      </span>
                    </div>
                    <div className={styles.row}>
                      <img
                        className={
                          languageDirection === 'rtl'
                            ? styles.iconTopBar.concat(' ').concat(styles.iconTopBarRtl)
                            : styles.iconTopBar
                        }
                        src={
                          'https://phonedo-media.fra1.digitaloceanspaces.com/landing-page/widget/chargebacks.png'
                        }
                        alt="arrowUp"
                      />
                      <span className={styles.headerTopBar}>
                        <I18n t="Chargebacks"></I18n> 0%
                      </span>
                    </div>
                  </div>
                  <div className={styles.topbarSide}>
                    <div className={styles.row}>
                      <img
                        className={
                          languageDirection === 'rtl'
                            ? styles.iconTopBar.concat(' ').concat(styles.iconTopBarRtl)
                            : styles.iconTopBar
                        }
                        src={
                          'https://phonedo-media.fra1.digitaloceanspaces.com/landing-page/widget/arrow-up.png'
                        }
                        alt="arrowUp"
                      />
                      <span className={styles.headerTopBar}>
                        <I18n t="Margins"></I18n> %35+
                      </span>
                    </div>
                    <div className={styles.row}>
                      <img
                        className={
                          languageDirection === 'rtl'
                            ? styles.iconTopBar.concat(' ').concat(styles.iconTopBarRtl)
                            : styles.iconTopBar
                        }
                        src={
                          'https://phonedo-media.fra1.digitaloceanspaces.com/landing-page/widget/star.png'
                        }
                        alt="star"
                      />
                      <span className={styles.headerTopBar}>
                        <I18n t="Reviews"></I18n> 5
                      </span>
                    </div>
                  </div>
                  <div className={styles.topbarSide}>
                    <img
                      className={
                        languageDirection === 'rtl' ? styles.closeIconRtl : styles.closeIcon
                      }
                      src={
                        'https://phonedo-media.fra1.digitaloceanspaces.com/landing-page/widget/close.png'
                      }
                      alt="close"
                      onClick={() => {
                        setCloseTopBar(true);
                      }}
                    />
                  </div>
                </>
              )}
            </div>
          )}
          {isMobile === false ? (
            <div
              style={languageDirection === 'rtl' ? { direction: 'rtl' } : { direction: 'ltr' }}
              className={`${styles.menuTopBar} ${closeTopBar ? styles.closeTopBarMenu : ''}`}
            >
              <div
                className={`${styles.menuSide} ${
                  languageDirection === 'rtl' ? styles.menuSideRtl : ''
                }`}
              >
                <img
                  src={
                    'https://phonedo-media.fra1.digitaloceanspaces.com/landing-page/widget/widgetIcon.png'
                  }
                  alt={'widgetIcon'}
                  className={styles.widgetIcon}
                />
                <div
                  className={styles.topnav}
                  style={languageDirection === 'rtl' ? { direction: 'rtl' } : { direction: 'ltr' }}
                >
                  <a href={`/${window.location.pathname.slice(1, 3)}`}>
                    <I18n t="Home"></I18n>
                  </a>
                  <a href={`/${window.location.pathname.slice(1, 3)}/guide`}>
                    <I18n t="Guide"></I18n>
                  </a>
                  <a
                    href={`/${window.location.pathname.slice(1, 3)}/plans-pricing`}
                    className={styles.active}
                  >
                    <I18n t="Plans & Pricing"></I18n>
                  </a>
                </div>
              </div>
              {/* <div className={`${styles.section9} ${styles.section15}`}> */}
              {/* <button className={styles.signupUrlSmall} onClick={redirect}><I18n t="Sign Up"></I18n></button> */}
              {/* </div> */}
              <LanguageMenu
                right={languageDirection === 'rtl' ? true : false}
                setLanguageDirection={setLanguageDirection}
              ></LanguageMenu>
            </div>
          ) : (
            <div
              className={`${styles.menuMobile} ${closeTopBar ? styles.closeMenuMobile : ''}`}
              style={languageDirection === 'rtl' ? { direction: 'rtl' } : { direction: 'ltr' }}
            >
              <img
                src={
                  'https://phonedo-media.fra1.digitaloceanspaces.com/landing-page/widget/widgetIcon.png'
                }
                alt={'widgetIcon'}
                className={`${styles.menuSideMobile} ${styles.widgetIcon}`}
              />
              <div className={`${styles.menuSideMobile}`}>
                <img
                  src={
                    'https://phonedo-media.fra1.digitaloceanspaces.com/landing-page/widget/Menu.png'
                  }
                  alt={'MenuIcon'}
                  className={`${styles.MenuIcon} ${
                    languageDirection === 'rtl' ? styles.MenuIconRtl : ''
                  }`}
                  onClick={() => {
                    setMenu(true);
                  }}
                />
              </div>
            </div>
          )}
          <div
            className={`${
              languageDirection === 'rtl'
                ? styles.plansPricingBody.concat(' ').concat(styles.plansPricingBodyRtl)
                : styles.plansPricingBody
            }`}
          >
            <div>
              <div className={styles.content}>
                <div className={styles.header}>
                  <I18n t="Simple pricing for your Business"></I18n>
                </div>
                <div className={styles.subheader}>
                  <I18n t="Choose the plan that most suits your business needs"></I18n>
                </div>
              </div>
              <div className={styles.bundles}>
                <div className={styles.card}>
                  <div className={styles.title}>Basic</div>
                  <div className={styles.sum}>100</div>
                  <div className={styles.minutes}>
                    <I18n t="Minutes"></I18n>
                  </div>
                  <div className={styles.totalCost}>
                    <I18n t="Total cost of"></I18n>: 95$
                  </div>
                  <div className={styles.priceper}>
                    <I18n t="Price per minute"></I18n>= $0.95
                  </div>
                  <Divider />
                  <div className={styles.auto}>
                    <I18n t="Auto Reacharge"></I18n>
                  </div>
                  <button
                    onClick={() => {
                      history.push(`/${window.location.pathname.slice(1, 3)}`, 'section8');
                    }}
                    className={styles.btn}
                  >
                    <I18n t="Get" /> Basic <I18n t="paln" />
                  </button>
                </div>
                <div className={styles.card}>
                  <div className={styles.title}>Premium</div>
                  <div className={styles.sum}>300</div>
                  <div className={styles.minutes}>
                    <I18n t="Minutes"></I18n>
                  </div>
                  <div className={styles.totalCost}>
                    <I18n t="Total cost of"></I18n>: 270$
                  </div>
                  <div className={styles.priceper}>
                    <I18n t="Price per minute"></I18n>= $0.90
                  </div>
                  <Divider />
                  <div className={styles.auto}>
                    <I18n t="Auto Reacharge"></I18n>
                  </div>
                  <button
                    onClick={() => {
                      history.push(`/${window.location.pathname.slice(1, 3)}`, 'section8');
                    }}
                    className={styles.btn}
                  >
                    <I18n t="Get" /> Pro <I18n t="paln" />
                  </button>
                </div>
                <div className={styles.card}>
                  <div className={styles.title}>Growth</div>
                  <div className={styles.sum}>1000</div>
                  <div className={styles.minutes}>
                    <I18n t="Minutes"></I18n>
                  </div>
                  <div className={styles.totalCost}>
                    <I18n t="Total cost of"></I18n>: 850$
                  </div>
                  <div className={styles.priceper}>
                    <I18n t="Price per minute"></I18n>= $0.85
                  </div>
                  <Divider />
                  <div className={styles.auto}>
                    <I18n t="Auto Reacharge"></I18n>
                  </div>
                  <button
                    onClick={() => {
                      history.push(`/${window.location.pathname.slice(1, 3)}`, 'section8');
                    }}
                    className={styles.btn}
                  >
                    <I18n t="Get" /> Growth <I18n t="paln" />
                  </button>
                </div>
                <div className={`${styles.card} ${styles.cardPro}`}>
                  <div className={styles.title} style={{ color: 'white' }}>
                    Enterprice
                  </div>
                  <div style={{ color: 'white' }} className={styles.sum}>
                    3000
                  </div>
                  <div className={styles.minutes} style={{ color: 'white' }}>
                    <I18n t="Minutes"></I18n>
                  </div>
                  <div style={{ color: 'white' }} className={styles.totalCost}>
                    <I18n style={{ color: 'white' }} t="Total cost of"></I18n>: 2,400$
                  </div>
                  <div style={{ color: 'white' }} className={styles.priceper}>
                    <I18n t="Price per minute"></I18n>= $0.80
                  </div>
                  <Divider />
                  <div style={{ color: 'white' }} className={styles.auto}>
                    <I18n t="Auto Reacharge"></I18n>
                  </div>
                  <button
                    onClick={() => {
                      history.push(`/${window.location.pathname.slice(1, 3)}`, 'section8');
                    }}
                    className={styles.btn}
                  >
                    <I18n t="Get" /> Enterprice <I18n t="paln" />
                  </button>
                </div>
              </div>
            </div>

            <div
              style={{ marginTop: '180px' }}
              className={`${
                languageDirection === 'rtl'
                  ? style.section.concat(' ').concat(style.sectionRtl)
                  : style.section
              } ${styles.section1Media} ${closeTopBar ? style.closeMenuMobile : ''}`}
            >
              {/* <img className={languageDirection === "rtl" ? style.homeScreen.concat(" ").concat(style.rtlFirstBackground) : style.homeScreen} src={"https://phonedo-media.fra1.digitaloceanspaces.com/landing-page/widget/section15%20%281%29.png"} alt={'Section15'} /> */}
              <img
                className={
                  languageDirection === 'rtl'
                    ? style.homeScreen.concat(' ').concat(style.rtlFirstBackground)
                    : style.homeScreen
                }
                src={
                  'https://phonedo-media.fra1.digitaloceanspaces.com/landing-page/widget/section15%20%281%29.png'
                }
                alt={'Section15'}
              />
              <img
                className={
                  languageDirection === 'rtl'
                    ? style.homeScreenMobile15.concat(' ').concat(style.rtlFirstBackground)
                    : style.homeScreenMobile15
                }
                src={
                  'https://phonedo-media.fra1.digitaloceanspaces.com/landing-page/widget/contactUsMobile.png'
                }
                alt={'contactUsMobile'}
              />
              <div className={style.flex1}>
                <div
                  style={languageDirection === 'rtl' ? { marginRight: '7%' } : { marginLeft: '7%' }}
                  className={style.flexSection1}
                >
                  <div className={style.contectForm}>
                    <div>
                      <Form<ConectUsForm>
                        initialValues={landingPageStore.conectUsForm}
                        onSubmit={async (form: any) => {
                          await landingPageStore.submitConactUs(form);
                        }}
                      >
                        {({ submitError, handleSubmit, form, values }) => (
                          <form onSubmit={handleSubmit} className={style.registrationForm}>
                            {/* <div className={style.contentRegistrationForm}> */}
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                              }}
                            >
                              <div
                                style={{
                                  alignSelf: 'center',
                                  marginTop: '15%',
                                }}
                              >
                                <PageTitle
                                  // className={style.header15}
                                  style={{ justifyContent: 'flex-start' }}
                                  title={I18n.getTranslation(location, 'Contact us')}
                                />
                                <SubTitle
                                  // className={style.subheader15}
                                  style={{
                                    justifyContent: 'flex-start',
                                    padding: 'unset',
                                    marginBottom: '4%',
                                  }}
                                  title={I18n.getTranslation(
                                    location,
                                    'Want to share something with us?'
                                  )}
                                />
                              </div>
                              <div className={style.fieldFlexC}>
                                <Field
                                  name={'type'}
                                  options={ReasonForRequest}
                                  placeholder={I18n.getTranslation(location, 'Reason for request')}
                                  component={InputSelect}
                                />
                                <div className={style.fieldFlex1}>
                                  <Field
                                    name={'fullName'}
                                    inputType={'text'}
                                    placeholder={I18n.getTranslation(location, 'Full name')}
                                    component={InputField}
                                    inputClass={style.bigInputText2}
                                  />
                                  <Field
                                    name={'companyName'}
                                    inputType={'text'}
                                    placeholder={I18n.getTranslation(location, 'Company name')}
                                    component={InputField}
                                    inputClass={style.bigInputText2}
                                  />
                                </div>
                                <Field
                                  name={'emailAddress'}
                                  inputType={'text'}
                                  placeholder={I18n.getTranslation(location, 'Email Address')}
                                  component={InputField}
                                  inputClass={style.bigInputText}
                                />
                                <div style={{ display: 'flex' }} className={style.fieldFlex2}>
                                  <Field
                                    name={'countryCode'}
                                    options={countryCodeList}
                                    placeholder={I18n.getTranslation(location, 'Code')}
                                    component={InputSelectCountry}
                                  />
                                  <Field
                                    style={{ width: '70%' }}
                                    name={'telephone'}
                                    inputType={'text'}
                                    placeholder={I18n.getTranslation(location, 'Telephone')}
                                    component={InputField}
                                    inputClass={style.field}
                                  />
                                </div>
                                <Field
                                  name={'description'}
                                  inputType={'text'}
                                  // placeholder={I18n.getTranslation(location, "Subject")}
                                  inputClass={style.bigInputText1}
                                  component={InputTextArea}
                                />
                                {submitError && (
                                  <div className={style.submitError}>
                                    <I18n t={submitError} />
                                  </div>
                                )}
                              </div>
                              <div style={{ width: '100%', textAlign: 'center' }}>
                                <button
                                  style={{
                                    position: 'relative',
                                    color: '#33354e',
                                  }}
                                  className={
                                    languageDirection === 'rtl'
                                      ? style.smallButton15
                                          .concat(' ')
                                          .concat(style.smallButton15)
                                          .concat(' ')
                                          .concat(style.smallButtonM1)
                                      : style.smallButton15.concat(' ').concat(style.smallButtonM1)
                                  }
                                >
                                  <I18n t="Send the message" />{' '}
                                </button>
                              </div>
                            </div>
                          </form>
                        )}
                      </Form>
                    </div>
                  </div>
                  {/* <img  className={languageDirection === "rtl" ? style.homeScreenMobile.concat(" ").concat(style.rtlFirstBackground) : styles.homeScreenMobile} src={"https://phonedo-media.fra1.digitaloceanspaces.com/landing-page/widget/section15%20%281%29.png"} alt={'Section15'} /> */}
                </div>
              </div>
            </div>
            <div
              className={`${styles.section} ${styles.borderSction16} ${styles.section16}`}
              style={languageDirection === 'rtl' ? { direction: 'rtl' } : { direction: 'ltr' }}
            >
              <div className={`${styles.part}`}>
                <img
                  src={
                    'https://phonedo-media.fra1.digitaloceanspaces.com/landing-page/widget/widgetIcon.png'
                  }
                  alt={'widget16'}
                  className={`${styles.widgetIcon16}`}
                />
              </div>
              <div className={`${styles.part} ${styles.middlePart}`}>
                <div>
                  @<I18n t="copyright 2022" />, <I18n t="All rights reserved" />
                </div>
                <div className={`${styles.partRow}`}>
                  <span
                    className={`${styles.partCol} ${styles.partColSpace}`}
                    onClick={() => {
                      history.push(`/${window.location.pathname.slice(1, 3)}/privacy-policy`);
                    }}
                  >
                    <I18n t="Privacy Policy" />
                  </span>
                  <span
                    className={`${styles.partCol}`}
                    onClick={() => {
                      history.push(`/${window.location.pathname.slice(1, 3)}/terms-condition`);
                    }}
                  >
                    <I18n t="Terms & Conditions" />
                  </span>
                </div>
              </div>
              <div className={`${styles.partimages}`}>
                <img
                  className={styles.contactIcon}
                  src={
                    'https://phonedo-media.fra1.digitaloceanspaces.com/landing-page/widget/Facebook.png'
                  }
                  alt={'facebook'}
                  onClick={() => {
                    window.open('https://www.facebook.com/Fulldo-103351595734254');
                  }}
                />
                <img
                  className={styles.contactIcon}
                  src={
                    'https://phonedo-media.fra1.digitaloceanspaces.com/landing-page/widget/instagram.png'
                  }
                  alt={'instagram'}
                  onClick={() => {
                    window.open('https://www.instagram.com/fullwidget');
                  }}
                />
                <img
                  className={styles.contactIcon}
                  src={
                    'https://phonedo-media.fra1.digitaloceanspaces.com/landing-page/widget/twitter.png'
                  }
                  alt={'twitter'}
                  onClick={() => {
                    window.open('https://twitter.com/FullWidget');
                  }}
                />
                <img
                  className={styles.contactIcon}
                  src={
                    'https://phonedo-media.fra1.digitaloceanspaces.com/landing-page/widget/youtube.png'
                  }
                  alt={'youtube'}
                  onClick={() => {
                    window.open('https://www.youtube.com/channel/UC_Q6WW9XCMZtxv69TFzdeYg');
                  }}
                />
                <img
                  className={styles.contactIcon}
                  src={
                    'https://phonedo-media.fra1.digitaloceanspaces.com/landing-page/widget/tiktok.svg'
                  }
                  alt={'tiktok'}
                  onClick={() => {
                    window.open('https://www.tiktok.com/@fullwidget');
                  }}
                />
                <img
                  className={styles.contactIcon}
                  src={
                    'https://phonedo-media.fra1.digitaloceanspaces.com/landing-page/widget/linkedin.svg'
                  }
                  alt={'linkedin'}
                  onClick={() => {
                    window.open('https://www.linkedin.com/company/full-do');
                  }}
                />
              </div>
            </div>
          </div>
          <div id="bottombar" className={styles.bottombar}>
            <div className={styles.flex}>
              <button
                onClick={() => {
                  history.push(`/${window.location.pathname.slice(1, 3)}`, 'section8');
                }}
                style={{ position: 'relative' }}
                className={styles.button}
              >
                <I18n t="Download The App"></I18n>
              </button>
              <span
                style={
                  languageDirection === 'rtl'
                    ? { direction: 'rtl', position: 'relative' }
                    : { position: 'relative' }
                }
                className={styles.bottombarTitle}
              >
                <I18n t="With our Widget on your site your visitors can talk or chat with our Live Reps 247"></I18n>
              </span>
              <div style={{ display: 'flex', marginInlineEnd: '50px' }}>
                <span style={{ position: 'relative' }} className={styles.subHeaderTopBar}>
                  <I18n t="Try It Now"></I18n>
                </span>
                <div
                  className={`${styles.bottomArrow} ${
                    languageDirection === 'rtl' ? styles.bottomArrowRtl : ''
                  } `}
                >
                  <img
                    style={{ position: 'relative' }}
                    alt={'arrowNextWhite'}
                    src={
                      'https://phonedo-media.fra1.digitaloceanspaces.com/landing-page/widget/arrow_next_white2.svg'
                    }
                  ></img>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
