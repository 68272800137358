import { FieldRenderProps } from 'react-final-form';
import Select from 'react-select';
import styles from './form.module.scss';
import React from 'react';

type Props<T> = FieldRenderProps<T, HTMLInputElement>;

export const InputSelectCountry = <T extends string>(props: Props<T>) => {
  const customStyles = {
    control: (provided, state) => ({
      borderRadius: 8,
      textAlign: 'start' as const,
      borderStyle: 'solid',
      borderWidth: 1,
      display: 'flex',
      borderColor: state.isFocused && props.borderColorFocus ? props.borderColorFocus : '#cccccc',
      //height: '4.49vh',
      //width: '65%',
      marginTop: 42,
      //marginInlineStart: 116,
      color: '#ffffff',
      // marginInlineStart: '10.8vh',
      height: '5.75vh',
      fontWeight: 500,
      fontSize: 14,
      width: '8vw',
      // "@media (max-width: 1800px)": {
      //   ...styles["@media (max-width: 1800px)"],
      //   width: '116%',
      //   marginInlineStart: '84%',

      // },
      '@media (max-width: 1300px)': {
        ...styles['@media (max-width: 1300px)'],
        borderRadius: 5.1875,
        // width: 100,
        height: '5.4vh',
        // marginInlineStart: 70,
        fontSize: 12,
        fontWeight: 400,
        fontFamily: 'Rubik',
      },
      '@media (max-width: 640px)': {
        ...styles['@media (max-width: 640px)'],
        width: 100,
        height: 50,
        // marginInlineStart: 80,
        marginTop: 45,
        marginInlineStart: 'auto',
      },
    }),
    menu: (base) => ({
      ...base,
      // width:props.menuStyle&& props.menuStyle,
      paddingTop: 0,
      paddingBottom: 10,
      //width: 145,
      // marginInlineStart: 115,
      // marginTop: -25,
      backgroundColor: '#33354E',
      color: ' #191919',
      borderWidth: 2,
      borderStyle: 'solid',
      borderColor: '#ffffff',
      borderRadius: 10,
      textColor: '#ffffff',
      width: '13vh',
      marginInlineStart: '10.8vh',
      marginTop: '-1vh',
      '@media (max-width: 1800px)': {
        ...styles['@media (max-width: 1800px)'],

        width: '153%',
        marginInlineStart: '90%',
      },
      '@media (max-width: 1300px)': {
        ...styles['@media (max-width: 1300px)'],
        width: 100,
        height: 151,
        overflowY: 'scroll',
        marginInlineStart: 70,
        marginTop: -11,
        fontSize: 12,
        fontWeight: 400,
        fontFamily: 'Rubik',
      },
      '@media (max-width: 640px)': {
        ...styles['@media (max-width: 640px)'],

        marginInlineStart: 80,
        width: 100,
        marginTop: -10,
      },
    }),
    option: (base, state) => ({
      ...base,
      backgroundColor: state.isSelected
        ? '#000000'
        : state.isFocused
        ? '#41424e'
        : base.backgroundColor,
      color: ' #ffffff',
    }),
  };

  const { meta, input } = props;

  return (
    <>
      <Select
        isSearchable={false}
        styles={customStyles}
        className={styles.selectCountry}
        options={props.options}
        onChange={input.onChange}
        value={props.options.filter((option) => option === input.value)}
        formatOptionLabel={(option, { context }) => {
          return context === 'menu' || !props.showValueInLabel ? option.label : option.value;
        }}
        placeholder={props.placeholder}
        ref={(ref) => {
          props.focusInput ? props.focusInput(ref) : (ref = null);
        }}
      />
      {(meta.touched && meta.error) ||
        (meta.submitError && <div className="submitError">{meta.error || meta.submitError}</div>)}
    </>
  );
};
