import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { LandingPage } from './screens/landingPage/landing-page.tsx';
import { TermsAndCondition } from './screens/landingPage/terms-and-condition.tsx';
import { PrivicyPolicy } from './screens/landingPage/privicy-policy.tsx';
import { Explanation } from './screens/explanation/explanation.tsx';
import { PlansPricing } from './screens/plansPricing/plans-pricing.tsx';

export const locales = ['en', 'he', 'ar', 'hu', 'gr', 'ru', 'es', 'fr', 'de', 'nl', 'pt'];
const base = '/:locale(he|en|ar|hu|gr|ru|es|fr|de|nl|pt)';
const navLang = `${window.navigator.language.substring(0, 2)}`;
const routing = (
  <Router>
    <div>
      <Switch>
        <Route exact path={`${base}`} component={LandingPage} />
        <Route exact path="/">
          <Redirect to={`/${navLang}`}></Redirect>
        </Route>
        <Route exact path={`${base}/terms-condition`}>
          <TermsAndCondition />
        </Route>
        <Route exact path={`${base}/privacy-policy`}>
          <PrivicyPolicy />
        </Route>
        <Route exact path={`${base}/plans-pricing`}>
          <PlansPricing />
        </Route>
        <Route exact path={`${base}/guide`}>
          <Explanation />
        </Route>
        <Route exact path={`${base}/guide/:platform?/:widgetPlatformAccessId?`}>
          <Explanation />
        </Route>
        <Route
          exact
          path={`${base}/guide/:platform?/:email?/:shopName?/:companyName?/:widgetPlatformAccessId?`}
        >
          <Explanation />
        </Route>
      </Switch>
    </div>
  </Router>
);

class App extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    return routing;
  }
}

export default App;
