import styles from './explanation.module.scss';
import 'react-slideshow-image/dist/styles.css';
import section1 from '../../images/explanation/section1.png';
import section1Mobile from '../../images/section1Mobile.png';
import section2 from '../../images/explanation/section2.png';
import section3 from '../../images/explanation/section3.png';
import section4 from '../../images/explanation/section4.png';
import section14_1 from '../../images/explanation/section14_1.png';
import section14_2 from '../../images/explanation/section14_2.png';
import section5 from '../../images/explanation/section5.png';
import section6 from '../../images/explanation/section6.png';
import section7 from '../../images/explanation/section7.png';
// import section8 from '../../images/explanation/section8.png';
import section9 from '../../images/explanation/section9.png';
import section10 from '../../images/explanation/section10.png';
import section11 from '../../images/explanation/section11.png';
import section12 from '../../images/explanation/section12.png';
import section13 from '../../images/explanation/section14.png';
// import section15 from '../../images/explanation/section15img.png';
// import green from '../../images/explanation/green.png';
import section15mobile from '../../images/section15mobile.png';
import { TopMenu } from '../../lib/final-form/top-menu.tsx';
import { AiOutlineArrowDown } from 'react-icons/ai';
import { AiOutlineArrowUp } from 'react-icons/ai';
import React, { useState, useCallback, useEffect } from 'react';
import I18n from '../../lib/lingui/i18n';
import { LanguageMenu } from '../../lib/final-form/languageMenu.tsx';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

export const Explanation = (props: any) => {
  const [sectionsArray] = useState<Array<string>>([
    'section1',
    'section2',
    'section3',
    'section4',
    'section5',
    'section6',
    'section7',
    'section8',
    'section9',
    'section10',
    'section11',
    'section12',
    'section13',
    'section14',
    'section15',
    'section16',
  ]);
  const [closeTopBar, setCloseTopBar] = useState(false);
  const currentSection = React.useRef<number>(1);
  const [isOpenPopup, setIsOpenPopup] = useState<boolean>(false);
  const [finalSection, setFinalSection] = useState<boolean>(false);
  // const [deviceType, setDeviceType] = useState<string>();
  const [languageDirection, setLanguageDirection] = useState<string>();
  const [menu, setMenu] = useState<boolean>(false);
  // const { shopifyPlatformAccessId } = useParams();
  const { widgetPlatformAccessId } = useParams();
  const { email } = useParams();
  let { platform } = useParams();
  platform = platform ?? null;
  const { companyName } = useParams();
  const { shopName } = useParams();
  const history = useHistory();
  const shopifyRedirectUrl =
    'https://full.bpo.do/auth/sign-up?email=' +
    email +
    '&companyName=' +
    companyName +
    '&widgetPlatformAccessId=' +
    widgetPlatformAccessId;
  const wixRedirectUrl = `https://widget.bpo.do/auth/sign-up?widgetPlatformAccessId=${widgetPlatformAccessId}`;
  const closeMenu = () => {
    if (window.location.pathname.includes('he') || window.location.pathname.includes('ar')) {
      setLanguageDirection('rtl');
    } else {
      setLanguageDirection('ltr');
    }
    setMenu(false);
  };
  const callbackObserve = useCallback(
    (entries: any, observer: any) => {
      entries.forEach((entry: any) => {
        if (entry.target) {
          if (entry.isIntersecting) {
            const currentSectionId = Number(entry.target.id.match(/\d+/));
            if (currentSectionId !== currentSection.current) {
              currentSection.current = currentSectionId;
            }
            if (currentSection.current === 15 && finalSection === false) {
              setFinalSection(true);
            } else if (currentSection.current !== 15) {
              setFinalSection(false);
            }
          }
        }
      });
    },
    [finalSection]
  );
  function redirect() {
    window.location.href = platform === 'shopify' ? shopifyRedirectUrl : wixRedirectUrl;
  }
  const sectionInViewport = (observer: IntersectionObserver) => {
    [
      'section1',
      'section2',
      'section3',
      'section4',
      'section5',
      'section6',
      'section7',
      'section8',
      'section9',
      'section10',
      'section11',
      'section12',
      'section13',
      'section14',
      'section15',
      'section16',
    ].forEach((section) => {
      const divSction = document.getElementById(section);
      if (divSction) {
        observer.observe(divSction);
      }
    });
  };
  useEffect(() => {
    // let typeOfDevice = navigator.userAgent.toLocaleLowerCase();
    // if (/window/i.test(typeOfDevice)) {
    //   setDeviceType('desktop')
    // }
    // else if (/android/i.test(typeOfDevice)) {
    //   setDeviceType('android')
    // }
    // else {
    //   setDeviceType('ios');

    // }
    setTimeout(function () {
      setIsOpenPopup(true);
    }, 500);
    if (window.location.pathname.includes('he') || window.location.pathname.includes('ar')) {
      setLanguageDirection('rtl');
    } else {
      setLanguageDirection('ltr');
    }
    const observer = new IntersectionObserver(callbackObserve, {
      threshold: [0.7],
    });
    sectionInViewport(observer);
    const unobserve = (arr: Array<string>, obs: any) => {
      arr.forEach((item) => {
        const divItem = document.getElementById(item);
        if (divItem) {
          obs.unobserve(divItem);
        }
      });
      obs.disconnect();
    };

    window.addEventListener('beforeunload', function () {
      unobserve(sectionsArray, observer);
    });
  }, [sectionsArray, callbackObserve]);

  const dropNextPage = useCallback((indexOfSection?: number) => {
    let toSection;
    // if (currentSection.current === 102) {
    //   currentSection.current = 10
    // }
    if (indexOfSection !== null && indexOfSection !== undefined) {
      toSection = 'section'.concat((indexOfSection + 1).toString());
      currentSection.current = indexOfSection + 1;
      setFinalSection(false);
    } else {
      toSection = 'section'.concat((Number(currentSection.current) + 1).toString());
    }

    document.getElementById(toSection)?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }, []);
  // const integrated1: { id: number, integratedName: string, integratedImg: string }[] = [
  //   { "id": 1, "integratedName": "WOO COMMERCE", "integratedImg": "wooLogo" },
  //   { "id": 2, "integratedName": "", "integratedImg": "" },
  //   { "id": 3, "integratedName": "Magento Commerce", "integratedImg": "magentLogo" },
  //   { "id": 4, "integratedName": "Shopify", "integratedImg": "shopifyLogo" },
  // ];
  // const integrated2: { id: number, integratedName: string, integratedImg: string }[] = [
  //   { "id": 1, "integratedName": "WOO COMMERCE", "integratedImg": "wooLogo" },
  //   { "id": 2, "integratedName": "", "integratedImg": "" },
  //   { "id": 3, "integratedName": "Magento Commerce", "integratedImg": "magentLogo" },
  //   { "id": 4, "integratedName": "Shopify", "integratedImg": "shopifyLogo" },
  // ];
  return (
    <div className={styles.overflow}>
      {isOpenPopup === true && platform === 'shopify' ? (
        <div
          id="popup1"
          className={styles.popup}
          style={languageDirection === 'rtl' ? { direction: 'rtl' } : { direction: 'ltr' }}
        >
          <div className={styles.popupBody}>
            <div
              className={styles.popupClose}
              onClick={() => {
                setIsOpenPopup(false);
              }}
            >
              <img
                src={
                  'https://phonedo-media.fra1.digitaloceanspaces.com/landing-page/widget/close_black.png'
                }
                alt={'close_popup'}
              />
            </div>
            <div className={`${styles.popupFlex}`}>
              <div className={`${styles.popupSide}`}>
                <div className={styles.popupTitle}>
                  <I18n t="Add widget to your website"></I18n>
                </div>
                <div className={styles.popupSubtitle}>
                  <I18n t="To make the Full Support Widget visible for your visitors, you need to turn it on in Shopify Theme Editor and save changes It's simple and takes only 2 clicks"></I18n>
                </div>
                <button
                  className={styles.popupBtn}
                  onClick={() => {
                    window.open(
                      `https://${shopName}/admin/themes/current/editor?context=apps`,
                      '_blank'
                    );
                  }}
                >
                  <I18n t="Go to Shopify Theme Editor"></I18n>
                </button>
              </div>
              <div className={`${styles.popupSide} ${styles.popupImg}`}>
                <img
                  src={
                    'https://phonedo-media.fra1.digitaloceanspaces.com/landing-page/widget/shopify-popup.png'
                  }
                  alt={'popup'}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
      {menu ? (
        <TopMenu close={closeMenu} />
      ) : (
        <div>
          {!closeTopBar && (
            <div
              id="topbar"
              className={`${
                languageDirection === 'rtl' ? `${styles.topbar} ${styles.topbarRtl}` : styles.topbar
              }`}
            >
              {isMobile === false ? (
                <>
                  {' '}
                  <div className={styles.topbarSide}>
                    <img
                      className={
                        languageDirection === 'rtl'
                          ? styles.iconTopBar.concat(' ').concat(styles.iconTopBarRtl)
                          : styles.iconTopBar
                      }
                      src={
                        'https://phonedo-media.fra1.digitaloceanspaces.com/landing-page/widget/arrow-up.png'
                      }
                      alt="arrowUp"
                    />
                    <span className={styles.headerTopBar}>
                      <I18n t="Sales"></I18n> {languageDirection === 'rtl' ? '500%+' : '+500%'}
                    </span>
                    <img
                      className={
                        languageDirection === 'rtl'
                          ? styles.iconTopBar.concat(' ').concat(styles.iconTopBarRtl)
                          : styles.iconTopBar
                      }
                      src={
                        'https://phonedo-media.fra1.digitaloceanspaces.com/landing-page/widget/arrow-down.png'
                      }
                      alt="arrowDown"
                    />
                    <span className={styles.headerTopBar}>
                      <I18n t="Returns"></I18n> {languageDirection === 'rtl' ? '95%-' : '-95%'}{' '}
                    </span>
                    <img
                      className={
                        languageDirection === 'rtl'
                          ? styles.iconTopBar.concat(' ').concat(styles.iconTopBarRtl)
                          : styles.iconTopBar
                      }
                      src={
                        'https://phonedo-media.fra1.digitaloceanspaces.com/landing-page/widget/arrow-up.png'
                      }
                      alt="arrowUp"
                    />
                    <span className={styles.headerTopBar}>
                      <I18n t="Margins"></I18n> {languageDirection === 'rtl' ? '35%+' : '+35%'}
                    </span>
                    <img
                      className={
                        languageDirection === 'rtl'
                          ? styles.iconTopBar.concat(' ').concat(styles.iconTopBarRtl)
                          : styles.iconTopBar
                      }
                      src={
                        'https://phonedo-media.fra1.digitaloceanspaces.com/landing-page/widget/star.png'
                      }
                      alt="star"
                    />
                    <span className={styles.headerTopBar}>
                      <I18n t="Reviews"></I18n> 5
                    </span>
                    <img
                      className={
                        languageDirection === 'rtl'
                          ? styles.iconTopBar.concat(' ').concat(styles.iconTopBarRtl)
                          : styles.iconTopBar
                      }
                      src={
                        'https://phonedo-media.fra1.digitaloceanspaces.com/landing-page/widget/chargebacks.png'
                      }
                      alt="star"
                    />
                    <span className={styles.headerTopBar}>
                      <I18n t="Chargebacks"></I18n> 0%
                    </span>
                  </div>
                  <div className={styles.topbarSide}>
                    <img
                      className={
                        languageDirection === 'rtl' ? styles.closeIconRtl : styles.closeIcon
                      }
                      src={
                        'https://phonedo-media.fra1.digitaloceanspaces.com/landing-page/widget/close.png'
                      }
                      alt="close"
                      onClick={() => {
                        setCloseTopBar(true);
                      }}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className={styles.topbarSide} style={{ paddingInlineStart: '28px' }}>
                    <div className={styles.row}>
                      <img
                        className={
                          languageDirection === 'rtl'
                            ? styles.iconTopBar.concat(' ').concat(styles.iconTopBarRtl)
                            : styles.iconTopBar
                        }
                        src={
                          'https://phonedo-media.fra1.digitaloceanspaces.com/landing-page/widget/arrow-down.png'
                        }
                        alt="arrowDown"
                      />
                      <span className={styles.headerTopBar}>
                        <I18n t="Returns"></I18n> %95-
                      </span>
                    </div>
                    <div className={styles.row}>
                      <img
                        className={
                          languageDirection === 'rtl'
                            ? styles.iconTopBar.concat(' ').concat(styles.iconTopBarRtl)
                            : styles.iconTopBar
                        }
                        src={
                          'https://phonedo-media.fra1.digitaloceanspaces.com/landing-page/widget/arrow-up.png'
                        }
                        alt="arrowUp"
                      />
                      <span className={styles.headerTopBar}>
                        <I18n t="Sales"></I18n> %500+
                      </span>
                    </div>
                    <div className={styles.row}>
                      <img
                        className={
                          languageDirection === 'rtl'
                            ? styles.iconTopBar.concat(' ').concat(styles.iconTopBarRtl)
                            : styles.iconTopBar
                        }
                        src={
                          'https://phonedo-media.fra1.digitaloceanspaces.com/landing-page/widget/chargebacks.png'
                        }
                        alt="arrowUp"
                      />
                      <span className={styles.headerTopBar}>
                        <I18n t="Chargebacks"></I18n> 0%
                      </span>
                    </div>
                  </div>
                  <div className={styles.topbarSide}>
                    <div className={styles.row}>
                      <img
                        className={
                          languageDirection === 'rtl'
                            ? styles.iconTopBar.concat(' ').concat(styles.iconTopBarRtl)
                            : styles.iconTopBar
                        }
                        src={
                          'https://phonedo-media.fra1.digitaloceanspaces.com/landing-page/widget/arrow-up.png'
                        }
                        alt="arrowUp"
                      />
                      <span className={styles.headerTopBar}>
                        <I18n t="Margins"></I18n> %35+
                      </span>
                    </div>
                    <div className={styles.row}>
                      <img
                        className={
                          languageDirection === 'rtl'
                            ? styles.iconTopBar.concat(' ').concat(styles.iconTopBarRtl)
                            : styles.iconTopBar
                        }
                        src={
                          'https://phonedo-media.fra1.digitaloceanspaces.com/landing-page/widget/star.png'
                        }
                        alt="star"
                      />
                      <span className={styles.headerTopBar}>
                        <I18n t="Reviews"></I18n> 5
                      </span>
                    </div>
                  </div>
                  <div className={styles.topbarSide}>
                    <img
                      className={
                        languageDirection === 'rtl' ? styles.closeIconRtl : styles.closeIcon
                      }
                      src={
                        'https://phonedo-media.fra1.digitaloceanspaces.com/landing-page/widget/close.png'
                      }
                      alt="close"
                      onClick={() => {
                        setCloseTopBar(true);
                      }}
                    />
                  </div>
                </>
              )}
            </div>
          )}
          {isMobile === false ? (
            <div
              style={languageDirection === 'rtl' ? { direction: 'rtl' } : { direction: 'ltr' }}
              className={`${styles.menuTopBar} ${closeTopBar ? styles.closeTopBarMenu : ''}`}
            >
              <div
                className={`${styles.menuSide} ${
                  languageDirection === 'rtl' ? styles.menuSideRtl : ''
                }`}
              >
                <img
                  src={
                    'https://phonedo-media.fra1.digitaloceanspaces.com/landing-page/widget/widgetIcon.png'
                  }
                  alt={'widgetIcon'}
                  className={styles.widgetIcon}
                />
                <div
                  className={styles.topnav}
                  style={languageDirection === 'rtl' ? { direction: 'rtl' } : { direction: 'ltr' }}
                >
                  <a href={`/${window.location.pathname.slice(1, 3)}`}>
                    <I18n t="Home"></I18n>
                  </a>
                  <a
                    href={`/${window.location.pathname.slice(1, 3)}/guide`}
                    className={styles.active}
                  >
                    <I18n t="Guide"></I18n>
                  </a>
                  <a href={`/${window.location.pathname.slice(1, 3)}/plans-pricing`}>
                    <I18n t="Plans & Pricing"></I18n>
                  </a>
                </div>
              </div>
              {/* <div className={`${styles.section9} ${styles.section15}`}> */}
              {/* <button className={styles.signupUrlSmall} onClick={redirect}><I18n t="Sign Up"></I18n></button> */}
              {/* </div> */}
              <LanguageMenu
                right={languageDirection === 'rtl' ? true : false}
                setLanguageDirection={setLanguageDirection}
              ></LanguageMenu>
            </div>
          ) : (
            <div
              className={`${styles.menuMobile} ${closeTopBar ? styles.closeMenuMobile : ''}`}
              style={languageDirection === 'rtl' ? { direction: 'rtl' } : { direction: 'ltr' }}
            >
              <img
                src={
                  'https://phonedo-media.fra1.digitaloceanspaces.com/landing-page/widget/widgetIcon.png'
                }
                alt={'widgetIcon'}
                className={`${styles.menuSideMobile} ${styles.widgetIcon}`}
              />
              <div className={`${styles.menuSideMobile}`}>
                <img
                  src={
                    'https://phonedo-media.fra1.digitaloceanspaces.com/landing-page/widget/Menu.png'
                  }
                  alt={'MenuIcon'}
                  className={`${styles.MenuIcon} ${
                    languageDirection === 'rtl' ? styles.MenuIconRtl : ''
                  }`}
                  onClick={() => {
                    setMenu(true);
                  }}
                />
              </div>
            </div>
          )}

          <div
            id="section1"
            className={`${languageDirection === 'rtl' ? styles.sectionRtl : styles.section2}${
              styles.section1Media
            } ${closeTopBar ? styles.closeMenuMobile : ''}`}
          >
            <img
              className={
                languageDirection === 'rtl'
                  ? styles.section1.concat(' ').concat(styles.rtlFirstBackground)
                  : styles.section1
              }
              src={section1}
              alt="section1"
            />
            <div className={languageDirection === 'rtl' ? styles.flex1Rtl : styles.flex1}>
              <div style={{ height: '100vh' }} className={styles.width50}>
                <div style={{ position: 'relative' }} className={styles.header}>
                  <I18n t="full Widget Guide"></I18n>{' '}
                  {platform ? (
                    <>
                      <I18n t="for"></I18n> <I18n t={platform}> </I18n>
                    </>
                  ) : (
                    ''
                  )}
                </div>
                {platform ? (
                  <>
                    <button
                      style={{ position: 'relative', color: '#33354e' }}
                      className={
                        languageDirection === 'rtl'
                          ? styles.smallButton
                              .concat(' ')
                              .concat(styles.smallButtonRtl)
                              .concat(' ')
                              .concat(styles.smallButtonM1)
                          : styles.smallButton.concat(' ').concat(styles.smallButtonM1)
                      }
                      onClick={redirect}
                    >
                      <I18n t="Sign Up"></I18n>
                    </button>
                  </>
                ) : (
                  ''
                )}
                <img
                  style={{ position: 'relative' }}
                  className={
                    languageDirection === 'rtl'
                      ? styles.homeScreenMobile.concat(' ').concat(styles.rtlFirstBackground)
                      : styles.homeScreenMobile
                  }
                  src={section1Mobile}
                  alt="section1Mobile"
                />
              </div>
            </div>
          </div>
          <div
            id="section2"
            className={`${
              languageDirection === 'rtl'
                ? `${styles.section2} ${styles.sectionRtl}`
                : styles.section2
            }`}
          >
            <div className={styles.flex1}>
              <div className={styles.flexSection2}>
                <div className={styles.headerDark}>
                  <I18n t="What is a"></I18n> <I18n t={platform ? platform : ''}> </I18n>{' '}
                  <I18n t="full Widget?"></I18n>
                </div>
                <div
                  className={
                    languageDirection === 'rtl' ? styles.subheaderDarkRtl : styles.subheaderDark
                  }
                >
                  <I18n t="Full is a decentralized multichannel multilingual multitasking multi disciplinary, human answering service for businesses worldwide it is Operated by highly skilled , trained, experienced call agents that will help you with sales, service and technical support in full availability 24/7/365 Full allows you to easily install a widget on your site Your customers will be able to click on it from anywhere on the site and talk to our Call Agents directly from your site using innovative technology without the need to use a telephone provider When they click, we will identify the customers spoken language and we will direct the call to the accurate call agents"></I18n>
                </div>
              </div>
              <div className={styles.flexEndMobile}>
                <img
                  className={
                    languageDirection === 'rtl' ? styles.section2imgRtl : styles.section2img
                  }
                  src={section2}
                  alt="section2"
                />
              </div>
            </div>
          </div>
          <div id="section3" className={`${languageDirection === 'rtl' ? styles.sectionRtl : ''}`}>
            <div className={styles.flexSection3}>
              <img className={styles.section3Img} src={section3} alt="section 3" />
              <div className={styles.headerDark3}>
                <I18n t="Full charges $099  per minute of actual call No monthly fees, no setup fee and no message fee"></I18n>
              </div>
            </div>
          </div>
          <div
            id="section4"
            className={`${languageDirection === 'rtl' ? styles.sectionRtl : ''} ${styles.section4}`}
          >
            <div className={styles.flex1}>
              <div className={styles.flexSection2}>
                <div className={`${styles.headerDark} ${styles.section2Media}`}>
                  <I18n t="How do you pay for the service?"></I18n>
                </div>
                <div className={styles.subheaderDark}>
                  <I18n t="Payment is made in advance, You choose how many minutes you want to recharge There is no validity to the minutes and as long as you didn't finish the quota of minutes they will be rmain as a balance for your use As soon as you run out of minutes, you will receive a notification  that your balance is about to end and you will be asked to top up Please note as long as your balance is positive the service will work smoothly If there are no minutes left, the service will be stopped And in order to avoid interuption to the service sequence, please make sure that the balance is positive or that automatic renewal is activated on your account"></I18n>
                </div>
                <div style={{ height: '10%' }}></div>
                <div className={`${styles.headerDark} ${styles.section2Media}`}>
                  <I18n t="How do you connect to the service?"></I18n>
                </div>
                <div className={styles.subheaderDark}>
                  <I18n t="it's easy and simple download the app from the App Store enable connection between Full and your site control panel our widget will automatically start appearing on all of your site pages in the right corner bottom"></I18n>
                </div>
              </div>
              <div className={styles.flexEndMobile}>
                <img
                  className={
                    languageDirection === 'rtl' ? styles.section4imgRtl : styles.section4img
                  }
                  src={section4}
                  alt="section4"
                />
              </div>
            </div>
          </div>
          <div
            id="section5"
            className={styles.section5}
            style={languageDirection === 'rtl' ? { direction: 'rtl' } : { direction: 'ltr' }}
          >
            <div className={styles.flexSection6}></div>
            <div className={styles.RowSection5}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignSelf: 'center',
                }}
              >
                <div className={styles.headerTitle}>
                  <I18n t="First page"></I18n>
                </div>
                <div className={styles.subHeaderTitle}>
                  <span className={styles.bold}>
                    <I18n t="Registration"></I18n>
                  </span>
                  :{' '}
                  <I18n t="sign up and fill out your business basic details and the name of business to identifying your account"></I18n>
                </div>
              </div>
              <img
                className={`${
                  languageDirection === 'rtl' ? styles.imgSectionRtl2 : styles.imgSection2
                }`}
                src={section5}
                alt={'section5'}
              />
            </div>
          </div>
          <div
            id="section6"
            className={styles.section6}
            style={languageDirection === 'rtl' ? { direction: 'rtl' } : { direction: 'ltr' }}
          >
            <div className={styles.flexSection6}></div>
            <div className={styles.columnSection6}>
              <div className={styles.headerTitle}>
                <I18n t="Second page"></I18n>
              </div>
              <div className={styles.subHeaderTitleWide}>
                <span className={styles.bold}>
                  <I18n t="Add new hotline"></I18n>
                </span>
                :{' '}
                <I18n t="to add a new hotline press on the purple button that says-add new hotline, and fill in the  details"></I18n>
              </div>
              <img
                className={`${
                  languageDirection === 'rtl' ? styles.imgSectionRtl : styles.imgSection
                }`}
                src={section6}
                alt={'section6'}
              />
              <div className={styles.flexSection6}></div>
            </div>
          </div>
          <div
            id="section7"
            className={styles.section5}
            style={
              languageDirection === 'rtl'
                ? { direction: 'rtl', background: '#EBF3EF' }
                : { direction: 'ltr', background: '#EBF3EF' }
            }
          >
            <div className={styles.flexSection6}></div>
            <div className={styles.RowSection5}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignSelf: 'center',
                }}
              >
                <div className={styles.headerTitle}>
                  <I18n t="Third page"></I18n>
                </div>
                <div
                  className={
                    languageDirection === 'rtl' ? styles.subHeaderTitleWide2 : styles.subHeaderTitle
                  }
                >
                  <I18n t="Set up the initial screen that our agents will be able to  see  with the information of your business  and knowledge and instructions for answering your calls set up the welcome message that you would want to be told, and location of the company you can set any rules that you would like, example must answer as a secretary"></I18n>
                </div>
              </div>
              <img
                className={`${
                  languageDirection === 'rtl' ? styles.imgSectionRtl2 : styles.imgSection2
                }`}
                src={section7}
                alt={'section7'}
              />
            </div>
          </div>
          <div
            id="section8"
            className={styles.section6}
            style={languageDirection === 'rtl' ? { direction: 'rtl' } : { direction: 'ltr' }}
          >
            <div className={styles.flexSection6}></div>
            <div className={styles.columnSection6}>
              <div className={styles.headerTitle}>
                <I18n t="Fourth page"></I18n>
              </div>
              <div className={styles.subHeaderTitleWide}>
                <I18n t="Choose the language ,accent, and gender of the agent,and what type of service you will be needing"></I18n>
              </div>
              <img
                className={`${
                  languageDirection === 'rtl' ? styles.imgSectionRtl : styles.imgSection
                }`}
                src={section6}
                alt={'section6'}
              />
              {/* <img className={`${languageDirection === "rtl" ? styles.imgSectionRtl : styles.imgSection}`} src={section8} alt={'section8'} /> */}
              <div className={styles.flexSection6}></div>
            </div>
          </div>
          <div
            id="section9"
            className={styles.section5}
            style={
              languageDirection === 'rtl'
                ? { direction: 'rtl', background: '#F5F3EE' }
                : { direction: 'ltr', background: '#F5F3EE' }
            }
          >
            <div className={styles.flexSection6}></div>
            <div className={styles.RowSection5}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignSelf: 'center',
                }}
              >
                <div className={styles.headerTitle}>
                  <I18n t="Fifth page"></I18n>
                </div>
                <div className={styles.subHeaderTitle}>
                  <I18n t="pick or add sound files welcoming  sound ,music on hold sound and voicemail sound"></I18n>
                </div>
              </div>
              <img
                style={{ marginTop: 'unset' }}
                className={`${
                  languageDirection === 'rtl' ? styles.imgSectionRtl2 : styles.imgSection2
                }`}
                src={section9}
                alt={'section9'}
              />
            </div>
          </div>
          <div
            id="section10"
            className={styles.section6}
            style={languageDirection === 'rtl' ? { direction: 'rtl' } : { direction: 'ltr' }}
          >
            <div className={styles.flexSection6}></div>
            <div className={styles.columnSection6}>
              <div className={styles.headerTitle}>
                <I18n t="Sixth page"></I18n>
              </div>
              <div className={styles.subHeaderTitleWide}>
                <div>
                  {' '}
                  1.{' '}
                  <I18n t="Insert a brief description about your business, so our call agents will have the information in front of their eays when they answer yor calls"></I18n>
                </div>
                <div>
                  {' '}
                  2.{' '}
                  <I18n t="Add Questions your customers frequently ask and the answers you would like our agent to answer This information will help our call agents to handel the caller's questions more efficiontly"></I18n>
                </div>
                <div>
                  {' '}
                  3. <I18n t="set up your work days and hours"></I18n>
                </div>
              </div>
              <img
                className={`${
                  languageDirection === 'rtl' ? styles.imgSectionRtl : styles.imgSection
                }`}
                src={section10}
                alt={'section10'}
              />
              <div className={styles.flexSection6}></div>
            </div>
          </div>
          <div
            id="section11"
            className={styles.section5}
            style={
              languageDirection === 'rtl'
                ? { direction: 'rtl', background: '#F0FFFB' }
                : { direction: 'ltr', background: '#F0FFFB' }
            }
          >
            <div className={styles.flexSection6}></div>
            <div className={styles.RowSection5}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignSelf: 'center',
                }}
              >
                <div className={styles.headerTitle}>
                  <I18n t="Seventh page"></I18n>
                </div>
                <div className={styles.subHeaderTitle}>
                  <span className={styles.bold}>
                    <I18n t="hotline questionnaire"></I18n>
                  </span>
                  :{' '}
                  <I18n t="Set up questions that you would like our call agent to ask your customer in order for you to understand the purpos of the call and the issue For example :Are you an existing customer? etc"></I18n>
                </div>
              </div>
              <img
                className={`${
                  languageDirection === 'rtl' ? styles.imgSectionRtl2 : styles.imgSection2
                }`}
                src={section11}
                alt={'section12'}
              />
            </div>
          </div>
          <div
            id="section12"
            className={`${styles.section} ${styles.section6}`}
            style={languageDirection === 'rtl' ? { direction: 'rtl' } : { direction: 'ltr' }}
          >
            <div className={styles.flexSection6}></div>
            <div className={styles.columnSection6}>
              <div className={styles.headerTitle}>
                <I18n t="Eighth Page"></I18n>
              </div>
              <div className={styles.subHeaderTitleWide}>
                <I18n t="Where do you want the Calls summaries to be sent to? Enter the selected reciptance full name, whatsapp number, email Address you can also put in multiple contacts to get the info for example: workers, secretaries family members and more"></I18n>
              </div>
              <img
                className={`${
                  languageDirection === 'rtl' ? styles.imgSectionRtl : styles.imgSection
                }`}
                src={section12}
                alt={'section12'}
              />
              <div className={styles.flexSection6}></div>
            </div>
          </div>
          <div
            id="section13"
            className={styles.section5}
            style={languageDirection === 'rtl' ? { direction: 'rtl' } : { direction: 'ltr' }}
          >
            <div className={styles.flexSection6}></div>
            <div className={styles.RowSection5}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignSelf: 'center',
                }}
              >
                <div className={styles.headerTitle}>
                  <I18n t="Ninth page"></I18n>
                </div>
                <div className={styles.subHeaderTitle}>
                  <I18n t="Choose the country, city, and the phone number you would like to use"></I18n>
                </div>
              </div>
              <img
                className={`${
                  languageDirection === 'rtl' ? styles.imgSectionRtl2 : styles.imgSection2
                }`}
                src={section13}
                alt={'section13'}
              />
            </div>
          </div>
          <div
            id="section14"
            className={styles.section4}
            style={languageDirection === 'rtl' ? { direction: 'rtl' } : { direction: 'ltr' }}
          >
            <div className={languageDirection === 'rtl' ? styles.flex1Rtl : styles.flex1}>
              <div style={{ width: '50%' }} className={styles.flexSection2}>
                <div
                  style={{ marginBottom: '2%' }}
                  className={`${styles.headerDark} ${styles.section2Media}`}
                >
                  <I18n t="How do you manage your account and settings after logging in?"></I18n>
                </div>
                <div className={styles.subheaderDark}>
                  <I18n t="With your Access details that you put In the initial login, you can always look in the personal area and then manage the response settings, payment details, view reports, you can also see lead details, listen to your call recordings and the customers messages"></I18n>
                </div>
                <div style={{ height: '10%' }}></div>
                <div
                  style={{ marginBottom: '2%' }}
                  className={`${styles.headerDark} ${styles.section2Media}`}
                >
                  <I18n t="How to run the widget on the site?"></I18n>
                </div>
                <div className={styles.subheaderDark}>
                  <I18n t="As mentioned, when you download the app from the"></I18n>{' '}
                  <I18n t={platform ? platform : ''}> </I18n>{' '}
                  <I18n t="App Store and join the widget service, it will automatically appear on all pages of your site when it is activated and configured to serve your customers in the best possible way"></I18n>
                </div>
                <div style={{ height: '10%' }}></div>
                <div
                  style={{ marginBottom: '2%' }}
                  className={`${styles.headerDark} ${styles.section2Media}`}
                >
                  <I18n t="How do you get the summary of calls?"></I18n>
                </div>
                <div className={styles.subheaderDark}>
                  <I18n t="After each call you will receive an email and WhatsApp with the caller's details, call summary, messages  and a recording file of the actual call"></I18n>
                </div>
              </div>
              <div>
                <div
                  className={
                    languageDirection === 'rtl' ? styles.section4imgRtl : styles.section4img
                  }
                >
                  <img
                    className={
                      languageDirection === 'rtl' ? styles.section14imgRtl : styles.section14img
                    }
                    src={section14_1}
                    alt="section14_1"
                  />
                  <div>
                    <img
                      style={{ marginTop: '25%' }}
                      className={
                        languageDirection === 'rtl' ? styles.section14aimgRtl : styles.section14aimg
                      }
                      src={section14_2}
                      alt="section14_2"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            id="section15"
            className={
              languageDirection === 'rtl'
                ? styles.section15.concat(' ').concat(styles.section15)
                : styles.section15
            }
          >
            <div style={{ justifyContent: 'center' }} className={styles.flex1}>
              <div className={styles.flexSection15}>
                {/* <img className={languageDirection === "rtl" ? styles.section2imgRtl : styles.section15green} src={green} alt="green" /> */}
                <div
                  className={`${
                    languageDirection === 'rtl' ? styles.headerDarkRtl15 : styles.headerDark15
                  } ${styles.section15Media}`}
                >
                  <I18n t="Your customers are already waiting! Click here now!"></I18n>
                </div>
                <button className={styles.signupSmall} onClick={redirect}>
                  <I18n t="Sign Up"></I18n>
                </button>
              </div>
              <div className={styles.flexSection15Img}>
                <img
                  className={styles.section15imgMobile}
                  src={section15mobile}
                  alt="section15mobile"
                />
              </div>
            </div>
          </div>
          <div
            id="section16"
            className={`${styles.borderSction16} ${styles.section16}`}
            style={languageDirection === 'rtl' ? { direction: 'rtl' } : { direction: 'ltr' }}
          >
            <div className={`${styles.part}`}>
              <img
                src={
                  'https://phonedo-media.fra1.digitaloceanspaces.com/landing-page/widget/widgetIcon.png'
                }
                alt={'widget16'}
                className={`${styles.widgetIcon16}`}
              />
            </div>
            <div className={`${styles.part} ${styles.middlePart}`}>
              <div>
                @<I18n t="copyright 2022" />, <I18n t="All rights reserved" />
              </div>
              <div className={`${styles.partRow}`}>
                <span
                  className={`${styles.partCol} ${styles.partColSpace}`}
                  onClick={() => {
                    history.push(`/${window.location.pathname.slice(1, 3)}/privacy-policy`);
                  }}
                >
                  <I18n t="Privacy Policy" />
                </span>
                <span
                  className={`${styles.partCol}`}
                  onClick={() => {
                    history.push(`/${window.location.pathname.slice(1, 3)}/terms-condition`);
                  }}
                >
                  <I18n t="Terms & Conditions" />
                </span>
              </div>
            </div>
            <div className={`${styles.part}`}>
              <img
                className={styles.contactIcon}
                src={
                  'https://phonedo-media.fra1.digitaloceanspaces.com/landing-page/widget/Facebook.png'
                }
                alt={'facebook'}
                onClick={() => {
                  window.open('https://www.facebook.com/Fulldo-103351595734254');
                }}
              />
              <img
                className={styles.contactIcon}
                src={
                  'https://phonedo-media.fra1.digitaloceanspaces.com/landing-page/widget/instagram.png'
                }
                alt={'instagram'}
                onClick={() => {
                  window.open('https://www.instagram.com/fullwidget');
                }}
              />
              <img
                className={styles.contactIcon}
                src={
                  'https://phonedo-media.fra1.digitaloceanspaces.com/landing-page/widget/twitter.png'
                }
                alt={'twitter'}
                onClick={() => {
                  window.open('https://twitter.com/FullWidget');
                }}
              />
              <img
                className={styles.contactIcon}
                src={
                  'https://phonedo-media.fra1.digitaloceanspaces.com/landing-page/widget/youtube.png'
                }
                alt={'youtube'}
                onClick={() => {
                  window.open('https://www.youtube.com/channel/UC_Q6WW9XCMZtxv69TFzdeYg');
                }}
              />
              <img
                className={styles.contactIcon}
                src={
                  'https://phonedo-media.fra1.digitaloceanspaces.com/landing-page/widget/tiktok.svg'
                }
                alt={'tiktok'}
                onClick={() => {
                  window.open('https://www.tiktok.com/@fullwidget');
                }}
              />
              <img
                className={styles.contactIcon}
                src={
                  'https://phonedo-media.fra1.digitaloceanspaces.com/landing-page/widget/linkedin.svg'
                }
                alt={'linkedin'}
                onClick={() => {
                  window.open('https://www.linkedin.com/company/full-do');
                }}
              />
            </div>
          </div>
          {isMobile === false ? (
            <>
              {
                finalSection ? (
                  <div className={styles.AiOutlineArrowDown}>
                    <AiOutlineArrowUp
                      size={35}
                      color="#42C6A5"
                      onClick={() => {
                        dropNextPage(0);
                      }}
                    />
                  </div>
                ) : (
                  <div className={styles.AiOutlineArrowDown}>
                    <AiOutlineArrowDown
                      size={35}
                      color="#42C6A5"
                      onClick={() => {
                        dropNextPage();
                      }}
                    />
                  </div>
                )
                // <img className={styles.imgDownArrowFixed} src={downArrowIcon} alt={downArrowIcon} onClick={() => { dropNextPage() }} />
              }
            </>
          ) : null}
          <div id="bottombar" className={styles.bottombar}>
            <div className={styles.flex} style={{ justifyContent: 'space-between' }}>
              <button
                onClick={() => {
                  history.push(`/${window.location.pathname.slice(1, 3)}`, 'section8');
                }}
                style={{ position: 'relative' }}
                className={styles.button}
              >
                <I18n t="Download The App"></I18n>
              </button>
              <span
                style={
                  languageDirection === 'rtl'
                    ? { direction: 'rtl', position: 'relative' }
                    : { position: 'relative' }
                }
                className={styles.bottombarTitle}
              >
                <I18n t="With our Widget on your site your visitors can talk or chat with our Live Reps 247"></I18n>
              </span>
              <div style={{ display: 'flex', marginInlineEnd: '50px' }}>
                <span style={{ position: 'relative' }} className={styles.subHeaderTopBar}>
                  <I18n t="Try It Now"></I18n>
                </span>
                <div
                  className={`${styles.bottomArrow} ${
                    languageDirection === 'rtl' ? styles.bottomArrowRtl : ''
                  } `}
                >
                  <img
                    style={{ position: 'relative' }}
                    alt={'arrowNextWhite'}
                    src={
                      'https://phonedo-media.fra1.digitaloceanspaces.com/landing-page/widget/arrow_next_white2.svg'
                    }
                  ></img>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
